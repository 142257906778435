// Custom scripts
import {
  aLCA,
  aLCP,
  aLCM,
  aLCL,
  aLCABoneContusion,
  aMenisci,
  aMeniscusPart,
  aMeniscusGrade,
  aMeniscusSurface,
  aMeniscusTearType,
  aChondropathyCompartment,
  aChondropathyArea,
  aChondropathyAreaPatella,
  aChondropathyGrade,
  aSynovialFluid,
  aBakerCyst,
  aLCACyst,
  aLCPCyst,
  aGCNMCyst,
  aGCNLCyst,
  aArthrosis,
  aBursitisSuprapatellar,
  aBursitisPrepatellar,
  aBursitisInfrapatellarSuperf,
  aBursitisInfrapatellarDeep,
  aArthrosisFP,
  aWiberg,
} from './MR_knee_arrays';

// General scripts
import { indent, br } from '../scripts/GeneralArrays';
import {
  formatArrayToText,
  capitelizeFirstLetter,
} from './../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // FP kloub
  const {
    synovialFluid,
    BakerCyst,
    BakerCystSize,
    LCACyst,
    LCACystSize,
    LCPCyst,
    LCPCystSize,
    GCNMCyst,
    GCNMCystSize,
    GCNLCyst,
    GCNLCystSize,
    arthrosis,
    LCA,
    LCADescription,
    LCAConclusion,
    LCP,
    LCM,
    LCL,
    LCABoneContusion,
    varices,
    infrapatplica,
  } = props.FT;
  const { arthrosisFP, Wiberg, dislocationFP } = props.FP;

  // Vazy
  let descriptionLigaments =
    aLCA[LCA].description +
    aLCABoneContusion[LCABoneContusion].description +
    (LCA === 4
      ? LCADescription !== ''
        ? ' - ' + LCADescription + '. '
        : '. '
      : '') +
    aLCP[LCP].description +
    aLCM[LCM].description +
    aLCL[LCL].description;
  let conclusionLigaments =
    (LCABoneContusion > 0
      ? aLCABoneContusion[LCABoneContusion].conclusion
      : aLCA[LCA].conclusion) +
    (LCA === 4
      ? LCAConclusion !== ''
        ? ' - ' + LCAConclusion + '. '
        : '. '
      : '') +
    aLCP[LCP].conclusion +
    aLCM[LCM].conclusion +
    aLCL[LCL].conclusion;

  // Menisky
  let menisci = [...props.menisci];

  menisci.sort((a, b) => {
    return a.side - b.side || b.grade - a.grade;
  });

  let menisciDescription = [];
  if (menisci.length > 0) {
    menisciDescription = menisci.map(item => {
      return item.tearType > 3
        ? 'Ruptura ' +
            aMenisci[item.side].description +
            aMeniscusTearType[item.tearType].description
        : 'Na PDFS v oblasti ' +
            aMeniscusPart[item.part].description +
            aMenisci[item.side].description +
            (item.grade < 2 // grade I nebo II
              ? aMeniscusGrade[item.grade].description
              : item.grade === 2 // grade III
              ? item.tearType === 0 || item.tearType === 1
                ? aMeniscusTearType[item.tearType].description +
                  aMeniscusGrade[item.grade].description +
                  aMeniscusSurface[item.surface].description // horizontální nebo vertikální
                : aMeniscusTearType[item.tearType].description // ostatní grade III
              : '');
    });
  } else {
    menisciDescription.push('Menisky bez strukturálních či signálových změn. ');
  }

  let descriptionMenisci = menisciDescription.join('');

  let menisciConclusion = menisci.map(item => {
    let menisciConclusionText = '';
    if (item.grade < 2) {
      menisciConclusionText =
        aMeniscusGrade[item.grade].conclusion +
        aMeniscusPart[item.part].conclusion +
        aMenisci[item.side].conclusion +
        '. ';
    } else {
      if (item.tearType === 0 || item.tearType === 1) {
        menisciConclusionText =
          aMeniscusTearType[item.tearType].conclusion +
          aMeniscusGrade[item.grade].conclusion +
          aMeniscusPart[item.part].conclusion +
          aMenisci[item.side].conclusion +
          aMeniscusSurface[item.surface].conclusion;
      } else if (item.tearType === 2 || item.tearType === 3) {
        menisciConclusionText =
          aMeniscusTearType[item.tearType].conclusion +
          aMeniscusGrade[item.grade].conclusion +
          aMeniscusPart[item.part].conclusion +
          aMenisci[item.side].conclusion +
          '. ';
      } else if (
        item.tearType === 4 ||
        item.tearType === 5 ||
        item.tearType === 6
      ) {
        menisciConclusionText =
          'Ruptura ' +
          aMenisci[item.side].conclusion +
          aMeniscusTearType[item.tearType].conclusion;
      } else {
        menisciConclusionText =
          'Susp. odtržení zadního rohu ' +
          aMenisci[item.side].conclusion +
          aMeniscusTearType[item.tearType].conclusion;
      }
    }
    return menisciConclusionText;
  });

  let conclusionMenisci = menisciConclusion.join('');

  // Chondropatie
  let chondropathy = [...props.chondropathy];
  let chondropathyDescription = [];
  let chondropathyConclusion = [];
  if (chondropathy.length > 0) {
    chondropathyDescription = chondropathy.map(item => {
      return (
        aChondropathyGrade[item.grade].description +
        (item.compartment < 4
          ? aChondropathyArea[item.area].description
          : aChondropathyAreaPatella[item.area].description) +
        aChondropathyCompartment[item.compartment].description +
        (item.size > 0 ? ' rozsahu ' + item.size + 'mm. ' : '. ')
      );
    });
    chondropathyConclusion = chondropathy.map(item => {
      return (
        aChondropathyGrade[item.grade].conclusion +
        'chondromalácie ' +
        (item.compartment < 4
          ? aChondropathyArea[item.area].conclusion
          : aChondropathyAreaPatella[item.area].conclusion) +
        aChondropathyCompartment[item.compartment].conclusion +
        (item.size !== '' ? ' (' + item.size + 'mm). ' : '. ')
      );
    });
  } else {
    chondropathyDescription.push(
      'Chrupavka femorotibiálního i femoropatelárního kloubu je zachovalé šíře a přiměřené signální intenzity. '
    );
  }

  let descriptionChondropathy = chondropathyDescription.join('');
  let conclusionnChondropathy = chondropathyConclusion.join('');

  // Synoviální tekutina, Bakerova cysta, jiné cysty, gonartróza, FP artróza, Wiberg
  let descriptionOther =
    aSynovialFluid[synovialFluid].description +
    aBakerCyst[BakerCyst ? 1 : 0].description +
    (BakerCyst && BakerCystSize === '' ? '. ' : '') +
    (BakerCystSize !== '' && BakerCyst
      ? ', velikosti ' + BakerCystSize + 'mm. '
      : '') +
    aLCACyst[LCACyst ? 1 : 0].description +
    (LCACyst && LCACystSize === '' ? '. ' : '') +
    (LCACystSize !== '' && LCACyst
      ? ', velikosti ' + LCACystSize + 'mm. '
      : '') +
    aLCPCyst[LCPCyst ? 1 : 0].description +
    (LCPCyst && LCPCystSize === '' ? '. ' : '') +
    (LCPCystSize !== '' && LCPCyst
      ? ', velikosti ' + LCPCystSize + 'mm. '
      : '') +
    aGCNMCyst[GCNMCyst ? 1 : 0].description +
    (GCNMCyst && GCNMCystSize === '' ? '. ' : '') +
    (GCNMCystSize !== '' && GCNMCyst
      ? ', velikosti ' + GCNMCystSize + 'mm. '
      : '') +
    aGCNLCyst[GCNLCyst ? 1 : 0].description +
    (GCNLCyst && GCNLCystSize === '' ? '. ' : '') +
    (GCNLCystSize !== '' && GCNLCyst
      ? ', velikosti ' + GCNLCystSize + 'mm. '
      : '') +
    (arthrosis + arthrosisFP > 0
      ? aArthrosis[arthrosis].description +
        aArthrosisFP[arthrosisFP].description
      : 'Bez degenerativních změn kolenního či femoropatelárního kloubu. ') +
    (dislocationFP
      ? 'Na PDFS zvýšení SI kostní dřeně zevního okraje laterálního kondylu femuru a mediálního okraje pately. '
      : '') +
    aWiberg[Wiberg].description +
    (infrapatplica
      ? 'Na PDFS neostře ohraničený lem zvýšení SI tuku Hoffova tělesa podél předpokládaného průběhu infrapatelární pliky. '
      : '') +
    (varices
      ? 'V podkoží oblasti kolenního kloubu varikózně rozšířené žíly. '
      : '');
  let conclusionOther =
    (dislocationFP
      ? 'St.p. nedávné přechodné laterální luxaci pately s reziduálním postkontuzním edémem její mediální hrany a laterálního kondylu femuru. '
      : '') +
    aSynovialFluid[synovialFluid].conclusion +
    aBakerCyst[BakerCyst ? 1 : 0].conclusion +
    (BakerCyst && BakerCystSize === '' ? '. ' : '') +
    (BakerCystSize !== '' && BakerCyst ? ' (' + BakerCystSize + 'mm). ' : '') +
    aLCACyst[LCACyst ? 1 : 0].conclusion +
    aLCPCyst[LCPCyst ? 1 : 0].conclusion +
    (GCNMCyst && GCNLCyst
      ? 'Ganglionové cysty femorálního origa obou hlav m. gastrocnemius. '
      : GCNMCyst
      ? aGCNMCyst[1].conclusion
      : GCNLCyst
      ? aGCNLCyst[1].conclusion
      : '') +
    aArthrosis[arthrosis].conclusion +
    aArthrosisFP[arthrosisFP].conclusion +
    (Wiberg === 2 || Wiberg === 3 ? aWiberg[Wiberg].conclusion : '') +
    (infrapatplica
      ? 'Mírné prosáknutí Hoffova tukového tělesa podél průběhu infrapatelární pliky - může odpovídat natažení pliky. '
      : '') +
    (varices ? 'Podkožní žilní varixy. ' : '');

  // Bursitidy
  const { suprapatellar, prepatellar, infrapatellarSuperf, infrapatellarDeep } =
    props.bursitis;

  //  Bursitis description & conclusion

  let aBursitisMild = [];
  if (suprapatellar === 1) aBursitisMild.push('suprapatelární');
  if (prepatellar === 1) aBursitisMild.push('prepatelární');
  if (infrapatellarSuperf === 1) aBursitisMild.push('povrchová infrapatelární');
  if (infrapatellarDeep === 1) aBursitisMild.push('hluboká infrapatelární');

  let aBursitisSevere = [];
  if (suprapatellar === 2) aBursitisSevere.push('suprapatelární');
  if (prepatellar === 2) aBursitisSevere.push('prepatelární');
  if (infrapatellarSuperf === 2)
    aBursitisSevere.push('povrchová infrapatelární');
  if (infrapatellarDeep === 2) aBursitisSevere.push('hluboká infrapatelární');

  let descriptionBursitis = '';
  let conclusionBursitis = '';

  if (aBursitisSevere.length > 0) {
    descriptionBursitis += capitelizeFirstLetter(
      formatArrayToText(aBursitisSevere) +
        ' bursa vykazuje na PDFS zvýšení SI obrazu rozšířené kolekce tekutiny s prosáknutím okolního tuku. '
    );
    conclusionBursitis +=
      'Pokročilá ' + formatArrayToText(aBursitisSevere) + ' bursitida. ';
  }

  if (aBursitisMild.length > 0) {
    descriptionBursitis += capitelizeFirstLetter(
      formatArrayToText(aBursitisMild) +
        ' bursa vykazuje na PDFS zvýšení SI obrazu mírného prosáknutí tuku až plošné kolekce tekutiny. '
    );
    conclusionBursitis +=
      'Mírná ' + formatArrayToText(aBursitisMild) + ' bursitida. ';
  }

  // Vedlejší nálezy
  const { customAddFindsDescription, customAddFindsConclusion } = props.general;
  let descriptionAddFinds = customAddFindsDescription;
  let conclusionAddFinds = customAddFindsConclusion;

  importDescription1(descriptionLigaments);
  importDescription1(descriptionMenisci + descriptionChondropathy);
  importDescription1(
    descriptionBursitis + descriptionOther + descriptionAddFinds
  );

  importConclusion1(
    conclusionLigaments +
      conclusionMenisci +
      conclusionnChondropathy +
      conclusionBursitis +
      conclusionOther +
      conclusionAddFinds
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
