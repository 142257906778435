import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const aOrganSize = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehce větší",
    description: "",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "větší",
    description: "",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně větší",
    description: "",
    conclusion: "",
    color: red,
  },
];

export const aOrganAtrophy = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná atrofie",
    description: "s mírnou redukcí parenchymu",
    conclusion: "mírná redukce parenchymu",
    color: yellow,
  },
  {
    value: 2,
    text: "atrofie",
    description: "s redukcí parenchymu",
    conclusion: "redukce parenchymu",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná atrofie",
    description: "s výraznou redukcí parenchymu",
    conclusion: "výrazná redukce parenchymu",
    color: red,
  },
];

export const aLiverSize = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "mírně zvětšena",
    conclusion: "mírně zvětšena",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "zvětšena",
    conclusion: "zvětšena",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "výrazně zvětšena",
    conclusion: "výrazně zvětšena",
    color: red,
  },
  {
    value: 4,
    text: "menší",
    description: "menší velikosti",
    conclusion: "menší velikosti",
    color: orange,
  },
];

export const aLiverParenchyma = [
  {
    value: 0,
    text: "normální",
    description: "přiměřené denzity parenchymu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná steatóza",
    description: "difuzně mírně nižší denzity",
    conclusion: "s obrazem mírné steatózy",
    color: yellow,
  },
  {
    value: 2,
    text: "steatóza difuzní",
    description: "difuzně nižší denzity",
    conclusion: "s obrazem difuzní steatózy",
    color: yellow,
  },
  {
    value: 3,
    text: "steatóza mapovitá",
    description: "mapovitě nižší denzity",
    conclusion: "s obrazem mapovité steatózy",
    color: yellow,
  },
  {
    value: 4,
    text: "cirhóza",
    description: "s nodulární přestavbou parenchymu",
    conclusion: "s obrazem cirhózy",
    color: red,
  },
];

export const aGBsize = [
  {
    value: 0,
    text: "normální",
    description: "přiměřené náplně",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "zvýšená",
    description: "zvětšen výraznější náplní",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "snížená",
    description: "zmenšen chabou náplní",
    conclusion: "",
    color: yellow,
  },
];

export const aGBcontent = [
  {
    value: 0,
    text: "normální",
    description: "normální denzity obsahu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "konkrement",
    description: "v lumen s ohraničenou hyperdenzitou",
    conclusion: "Cholecystolitiáza. ",
    color: orange,
  },
  {
    value: 2,
    text: "konkrementy",
    description: "v lumen s ohraničenými hyperdenzitami",
    conclusion: "Cholecystolitiáza. ",
    color: orange,
  },
  {
    value: 3,
    text: "sludge",
    description: "v lumen s denzní vrstvou",
    conclusion: "Sludge v žlučníku. ",
    color: orange,
  },
];

export const aGBwall = [
  {
    value: 0,
    text: "normální",
    description: "s tenkou stěnou",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "zesílena",
    description: "s difuzně zesílenou stěnou",
    conclusion: "Cholecystitida. ",
    color: orange,
  },
  {
    value: 2,
    text: "zesílena a prosak okolí",
    description:
      "s difuzně zesílenou stěnou a zvýšené denzity okolního tuku a lůžka žlučníku",
    conclusion: "Cholecystitida a pericholecystitida. ",
    color: orange,
  },
];

export const aBTextraSize = [
  {
    value: 0,
    text: "normální",
    description: "Přiměřené šíře lumen ductus hepatocholedochus. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční šíře",
    description: "Hraniční šíře lumen ductus hepatocholedochus. ",
    conclusion: "Hraniční šíře ductus hepatocholedochus. ",
    color: orange,
  },
  {
    value: 2,
    text: "dilatován",
    description: "Dilatace lumen ductus hepatocholedochus. ",
    conclusion: "Dilatace ductus hepatocholedochus. ",
    color: red,
  },
];

export const aBTintraSize = [
  {
    value: 0,
    text: "normální",
    description: "Intrahepatické žlučové cesty nejsou rozšířeny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční šíře",
    description: "Intrahepatické žlučové cesty hraniční šíře lumen. ",
    conclusion: "Hraniční šíře intrahepatických žlučových cest. ",
    color: orange,
  },
  {
    value: 2,
    text: "dilatovány",
    description: "Intrahepatické žlučové cesty dilatovány. ",
    conclusion: "Dilatace intrahepatických žlučových cest. ",
    color: red,
  },
];

export const aApendix = [
  {
    value: 0,
    text: "normální",
    description: "Apendix přiměřené šíře, bez reaktivních změn okolního tuku. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "po APPE",
    description: "Apendix nedif. - susp. st.p. APPE",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "zánět",
    description:
      "Difuzní zesílení stěny apendixu se infiltrativním zvýšením denzity okolního tuku. ",
    conclusion: "Appendicitida. ",
    color: red,
  },
  {
    value: 3,
    text: "apendikulární absces",
    description:
      "Ohraničená kolekce tekutiny se sytící se stěnou v místě předpokládaného průběhu apendixu, s infiltrativním zvýšením denzity okolního tuku. ",
    conclusion: "Apendikulární absces. ",
    color: red,
  },
];

export const aColon = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "divertikulóza sigmatu",
    description:
      "Výchlipky stěny sigmatu, bez rozšíření stěny či infiltrativního zvýšení denzity okolního tuku. ",
    conclusion: "Divertikulóza sigmatu. ",
    color: orange,
  },
  {
    value: 2,
    text: "divertikulóza tračníku",
    description:
      "Výchlipky stěny tračníku, bez rozšíření stěny či infiltrativního zvýšení denzity okolního tuku. ",
    conclusion: "Divertikulóza tračníku. ",
    color: orange,
  },
  {
    value: 3,
    text: "divertikulitida sigmatu",
    description:
      "V terénu četných divertiklů sigmatu rozšíření stěny střevní s infiltrativním zvýšením denzity okolního tuku. ",
    conclusion: "Divertikulitida sigmatu. ",
    color: red,
  },
];

export const aSpleenSize = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "mírně zvětšena",
    conclusion: "mírně zvětšena",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "zvětšena",
    conclusion: "zvětšena",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "výrazně zvětšena",
    conclusion: "výrazně zvětšena",
    color: red,
  },
  {
    value: 4,
    text: "menší",
    description: "menší velikosti",
    conclusion: "menší velikosti",
    color: orange,
  },
];

export const aPancreasSize = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná atrofie",
    description: "mírně atrofický",
    conclusion: "mírně atrofický",
    color: yellow,
  },
  {
    value: 2,
    text: "atrofie",
    description: "atrofický",
    conclusion: "atrofický",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná atrofie",
    description: "výrazně atrofický",
    conclusion: "výrazně atrofický",
    color: red,
  },
];

export const aPancreasParenchyma = [
  {
    value: 0,
    text: "normální",
    description: "nativně přiměřené denzity parenchymu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lipodystrofie",
    description: "nativně nižší denzity parenchymu",
    conclusion: "s obrazem lipodystrofie",
    color: yellow,
  },
];

export const aKidneyParenchyma = [
  {
    value: 0,
    text: "normální",
    description: "bez redukce parenchymu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná atrofie",
    description: "s mírnou redukcí parenchymu",
    conclusion: "mírnou redukcí parenchymu",
    color: yellow,
  },
  {
    value: 2,
    text: "atrofie",
    description: "s redukcí parenchymu",
    conclusion: "redukcí parenchymu",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná atrofie",
    description: "s výraznou redukcí parenchymu",
    conclusion: "výraznou redukcí parenchymu",
    color: red,
  },
];

export const aKidneyKPS = [
  {
    value: 0,
    text: "normální",
    description: "s normální šíří KPS",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hypotonie",
    description: "s rozšířením pánvičky, ale s přiměřenou šíří kalichů",
    conclusion: "obrazem hypotonie KPS",
    color: green,
  },
  {
    value: 2,
    text: "mírná dilatace",
    description: "s mírným rozšířením KPS",
    conclusion: "obrazem mírného městnání",
    color: orange,
  },
  {
    value: 3,
    text: "dilatace",
    description: "s rozšířením KPS",
    conclusion: "obrazem městnání",
    color: red,
  },
  {
    value: 3,
    text: "výrazná dilatace",
    description: "s výrazným rozšířením KPS",
    conclusion: "obrazem výrazného městnání",
    color: red,
  },
];

export const aOvarium = [
  {
    value: 0,
    text: "nehodnoceno",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description: "ovarium s věku přiměřeným nálezem. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nedif.",
    description: "ovarium není přesvědčivě diferencovatelné. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "cysta",
    description:
      "ovarium obsahující tekutinovou kolekci ohraničenou tenkou stěnou, celkové velikosti ",
    conclusion: "ovarium s cystou",
    color: orange,
  },
  {
    value: 4,
    text: "cysty",
    description:
      "ovarium obsahující několik tekutinových kolekcí ohraničených tenkou stěnou, největší velikosti ",
    conclusion: "ovarium s cystami",
    color: orange,
  },
];

export const aUrinaryBladder = [
  {
    value: 0,
    text: "nehodnocen",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description:
      "Močový měchýř s normálním obsahem, bez přesvědčivého patologického zesílení stěny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nehodnotitelný (chabá náplň)",
    description: "Pro chabou náplň je močový měchýř nehodnotitelný. ",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "zánět",
    description: "Difuzní zesílení stěny močového měchýře. ",
    conclusion: "Zánětlivé změny močového měchýře. ",
    color: red,
  },
];

export const aUterus = [
  {
    value: 0,
    text: "nehodnocena",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description:
      "Věku přiměřená velikost dělohy, bez přesvědčivých ložiskových změn. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nedif.",
    description: "Děloha chybí, susp. st.p. hysterektomii. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "myomatóza",
    description: "Nepravidelné kontury zvětšené dělohy. ",
    conclusion: "Susp. myomatóza dělohy. ",
    color: orange,
  },
];

export const aProstate = [
  {
    value: 0,
    text: "nehodnocena",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description: "Věku přiměřená velikost prostaty. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nedif.",
    description: "Prostata nedif., susp. st.p. prostatektomii. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "zvětšená",
    description: "Zvětšená prostata. ",
    conclusion: "Zvětšená prostata, susp. benigní hyperplázií. ",
    color: orange,
  },
];

export const aSigma = [
  {
    value: 0,
    text: "nehodnoceno",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description:
      "Pravidelná přiměřená šíře stěny sigmatu, bez reaktivních změn okolního tuku. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "divertikly",
    description:
      "Divertikly sigmatu, ale bez zesílení jeho stěny či reaktivních změn okolního tuku. ",
    conclusion: "Divertikly sigmatu. ",
    color: orange,
  },
  {
    value: 3,
    text: "divertikulitida",
    description:
      "Zesílení stěny sigmatu s četnými divertikly, s reaktivním prosáknutím okolního tuku. ",
    conclusion: "Divertikulitida sigmatu. ",
    color: red,
  },
];

/*export const aLN = [
  {
    value: 0,
    text: "paraaortální",
    description: "paraaortální",
    conclusion: "1",
    color: green,
  },
  {
    value: 1,
    text: "ilické společné vpravo",
    description: "ilické společné vpravo",
    conclusion: "1",
    color: green,
  },
  {
    value: 2,
    text: "ilické společné vlevo",
    description: "ilické společné vlevo",
    conclusion: "1",
    color: green,
  },
  {
    value: 3,
    text: "ilické zevní vpravo",
    description: "ilické zevní vpravo",
    conclusion: "1",
    color: green,
  },
  {
    value: 4,
    text: "ilické zevní vlevo",
    description: "ilické zevní vlevo",
    conclusion: "1",
    color: green,
  },
  {
    value: 5,
    text: "ilické vnitřní vpravo",
    description: "ilické zevní vpravo",
    conclusion: "1",
    color: green,
  },
  {
    value: 6,
    text: "mezenteriální",
    description: "mezenteriální",
    conclusion: "1",
    color: green,
  },
];*/

export const aLN = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční velikosti",
    description: "hraniční velikosti",
    conclusion: "hraniční velikosti",
    color: yellow,
  },
  {
    value: 2,
    text: "jedna zvětšena",
    description: "jedna zvětšena",
    conclusion: "jedna zvětšena",
    color: red,
  },
  {
    value: 3,
    text: "několik zvětšeno",
    description: "několik zvětšeno",
    conclusion: "několik zvětšeno",
    color: red,
  },
];

/*export const aLN = [
  {
    value: 0,
    text: "aortokavální",
    description: "interaortokavální",
    conclusion: "1",
    color: green,
  },
  {
    value: 1,
    text: "paraaortální levostranné",
    description: "paraaortální levostranné",
    conclusion: "1",
    color: green,
  },
  {
    value: 2,
    text: "ilické společné vpravo",
    description: "ilické společné vpravo",
    conclusion: "1",
    color: green,
  },
  {
    value: 3,
    text: "ilické společné vlevo",
    description: "ilické společné vlevo",
    conclusion: "1",
    color: green,
  },
  {
    value: 4,
    text: "ilické zevní vpravo",
    description: "ilické zevní vpravo",
    conclusion: "1",
    color: green,
  },
  {
    value: 5,
    text: "ilické zevní vlevo",
    description: "ilické zevní vlevo",
    conclusion: "1",
    color: green,
  },
  {
    value: 6,
    text: "ilické vnitřní vpravo",
    description: "ilické zevní vpravo",
    conclusion: "1",
    color: green,
  },
  {
    value: 7,
    text: "ilické vnitřní vlevo",
    description: "ilické zevní vlevo",
    conclusion: "1",
    color: green,
  },
  {
    value: 8,
    text: "celiakální",
    description: "celiakální",
    conclusion: "1",
    color: green,
  },
  {
    value: 9,
    text: "gastrické",
    description: "gastrické",
    conclusion: "1",
    color: green,
  },
  {
    value: 10,
    text: "hepatické",
    description: "hepatické",
    conclusion: "1",
    color: green,
  },
  {
    value: 11,
    text: "pankreatikoduodenální",
    description: "pankreatikoduodenální",
    conclusion: "1",
    color: green,
  },
  {
    value: 12,
    text: "pankreatosplenické",
    description: "pankreatosplenické",
    conclusion: "1",
    color: green,
  },
  {
    value: 13,
    text: "horní mezenterické",
    description: "horní mezenterické",
    conclusion: "1",
    color: green,
  },
  {
    value: 14,
    text: "dolní mezenterické",
    description: "dolní mezenterické",
    conclusion: "1",
    color: green,
  },
  {
    value: 15,
    text: "parakolické pravostranné",
    description: "parakolické pravostranné",
    conclusion: "1",
    color: green,
  },
  {
    value: 16,
    text: "parakolické střední",
    description: "parakolické střední",
    conclusion: "1",
    color: green,
  },
  {
    value: 17,
    text: "parakolické levostranné",
    description: "parakolické levostranné",
    conclusion: "1",
    color: green,
  },
  {
    value: 18,
    text: "ileokolické",
    description: "ileokolické",
    conclusion: "1",
    color: green,
  },
  {
    value: 19,
    text: "sigmoidní",
    description: "sigmoidní",
    conclusion: "1",
    color: green,
  },
];*/
