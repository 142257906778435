import { green, yellow, orange, red, grey } from '../scripts/GeneralArrays';

// Custom

export const aCustom = [
  {
    value: 0,
    label: 'ložisko',
    color: '#91d5ff',
  },
  {
    value: 1,
    label: 'atrofie',
    color: '#b7eb8f',
  },
  {
    value: 2,
    label: 'ostatní',
    color: '#ffc069',
  },
  {
    value: 3,
    label: 'varieta',
    color: '#fff566',
  },
];

// Protokoly

export const aProtocols = [
  {
    value: 0,
    text: 'zadní jáma',
  },
  {
    value: 1,
    text: 'MRA tepen',
  },
  {
    value: 2,
    text: 'MRA splavů',
  },
];

// Glióza

export const aFazekas = [
  {
    value: 0,
    text: 'grade 0',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Na T2 a FLAIR několik okrsků vyšší SI periventrikulární bílé hmoty, bez známek restrikce difuze. ',
    conclusion:
      'Ojedinělé okrsky nespecifické periventrikulární gliózy (Fazekas grade 1). ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'Na T2 a FLAIR místy splývající okrsky vyšší SI periventrikulární bílé hmoty, bez známek restrikce difuze. ',
    conclusion:
      "Středně pokročilá periventrikulární glióza (Fazekas grade 2), nejspíše při 'small vessel disease.' ",
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description:
      'Na T2 a FLAIR mapovitě splývající okrsky vyšší SI periventrikulární bílé hmoty, bez známek restrikce difuze. ',
    conclusion:
      "Pokročilá periventrikulární glióza (Fazekas grade 3), nejspíše při 'small vessel disease.' ",
    color: red,
  },
];

export const aGliosisInfratentorial = [
  {
    value: 0,
    text: 'bez gliózy',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: 'mezencefalon',
    description:
      'Na T2 a FLAIR okrsky vyšší SI v oblasti mezencefala, bez známek restrikce difuze. ',
    conclusion: 'Okrsky gliózy v oblasti mezencefala. ',
  },
  {
    value: 2,
    text: 'pons',
    description:
      'Na T2 a FLAIR okrsky vyšší SI v oblasti pontu, bez známek restrikce difuze. ',
    conclusion: 'Okrsky gliózy v oblasti pontu. ',
  },
  {
    value: 3,
    text: 'prodloužená mícha',
    description:
      'Na T2 a FLAIR okrsky vyšší SI v oblasti prodloužené míchy, bez známek restrikce difuze. ',
    conclusion: 'Okrsky gliózy v oblasti prodloužené míchy. ',
  },
];

// RS

export const aRSSupratentorial = [
  {
    value: 0,
    text: 'stac. ložiska',
    description:
      'progrese velikosti či počtu ložisek obrazu demyelinizace v supratentoriální bílé hmotě. ',
    conclusion:
      'progrese velikosti či počtu ložisek obrazu demyelinizace v supratentoriální bílé hmotě. ',
  },
  {
    value: 1,
    text: 'bez ložisek',
    description:
      'přesvědčivých ložisek obrazu demyelinizace v supratentoriální bílé hmotě. ',
    conclusion: 'ložisek v supratentoriální bílé hmotě. ',
  },
];

export const aRSInfratentorial = [
  {
    value: 0,
    text: 'stac. ložiska',
    description: 'nových ložisek vyšší SI v infratentoriální bílé hmotě. ',
    conclusion: 'nových ložisek v infratentoriální bílé hmotě. ',
  },
  {
    value: 1,
    text: 'bez ložisek',
    description:
      'přesvědčivých ložisek vyšší SI v infratentoriální bílé hmotě. ',
    conclusion: 'přesvědčivých ložisek v infratentoriální bílé hmotě. ',
  },
];

export const aRSIntramedular = [
  {
    value: 0,
    text: 'stac. ložiska',
    description:
      'nových intramedulárních ložisek v zobrazeném rozsahu krční míchy. ',
    conclusion: 'nových intramedulárních ložisek. ',
  },
  {
    value: 1,
    text: 'bez ložisek',
    description:
      'přesvědčivých intramedulárních ložisek v zobrazeném rozsahu krční míchy. ',
    conclusion: 'přesvědčivých intramedulárních ložisek. ',
  },
];

// Atrofie

export const aGCA = [
  {
    value: 0,
    text: 'grade 0',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Lehké povšechné zúžení gyrů a tak rozšíření sulků hemisfér mozkových. ',
    conclusion: 'Mírná celková atrofie mozku (GCA grade 1). ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 1-2',
    description:
      'Lehké až středně pokročilé povšechné zúžení gyrů a tak rozšíření sulků hemisfér mozkových. ',
    conclusion:
      'Mírná až středně pokročilá celková atrofie mozku (GCA grade 1-2). ',
    color: yellow,
  },
  {
    value: 3,
    text: 'grade 2',
    description:
      'Středně pokročilé povšechné zúžení gyrů a tak rozšíření sulků hemisfér mozkových. Širší postranní komory z úbytku bílé hmoty. ',
    conclusion: 'Středně pokročilá celková atrofie mozku (GCA grade 2). ',
    color: orange,
  },
  {
    value: 4,
    text: 'grade 2-3',
    description:
      'Středně pokročilé až výrazně pokročilé povšechné zúžení gyrů a tak rozšíření sulků hemisfér mozkových. Širší postranní komory z úbytku bílé hmoty. ',
    conclusion:
      'Středně až výrazně pokročilá celková atrofie mozku (GCA grade 2-3). ',
    color: orange,
  },
  {
    value: 5,
    text: 'grade 3',
    description:
      'Výrazné povšechné zúžení gyrů a tak rozšíření sulků hemisfér mozkových. Širší postranní komory z úbytku bílé hmoty. ',
    conclusion: 'Výrazně pokročilá celková atrofie mozku (GCA grade 3). ',
    color: red,
  },
];

export const aMTA = [
  {
    value: 0,
    text: 'grade 0',
    description: 'hipokampus normálního vzhledu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description: 'mírné rozšíření choroidální fisury (MTA grade 1). ',
    conclusion: 'grade 1',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'rozšíření choroidální fisury a mírné rozšíření temporálního rohu postranní komory z důvodu nevýrazné atrofie hipokampu (MTA grade 2). ',
    conclusion: 'grade 2',
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description:
      'nápadné rozšíření choroidální fisury a rozšíření temporálního rohu postranní komory z důvodu atrofie hipokampu (MTA grade 3). ',
    conclusion: 'grade 3',
    color: red,
  },
  {
    value: 4,
    text: 'grade 4',
    description:
      'nápadné rozšíření choroidální fisury a temporálního rohu postranní komory z důvodu výrazné atrofie hipokampu (MTA grade 4). ',
    conclusion: 'grade 4',
    color: red,
  },
];

export const aKoedam = [
  {
    value: 0,
    text: 'grade 0',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Oproti ostatním lalokům lehce výraznější zúžení gyrů a tak rozšíření sulků parietálních laloků (Koedam grade 1). ',
    conclusion: 'Mírná atrofie parietálních laloků (Koedam grade 1). ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'Oproti ostatním lalokům  výraznější zúžení gyrů a tak rozšíření sulků parietálních laloků (Koedam grade 2). ',
    conclusion: 'Atrofie parietálních laloků (Koedam grade 2). ',
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description:
      'Oproti ostatním lalokům lehce výrazné zúžení gyrů a tak rozšíření sulků parietálních laloků (Koedam grade 3). ',
    conclusion: 'Pokročilá atrofie parietálních laloků (Koedam grade 3). ',
    color: red,
  },
];

export const aFocalAtrophy = [
  {
    value: 0,
    text: 'není přítomna',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: 'frontální laloky',
    description:
      'Nápadnější redukce šíře gyrů a tak rozšíření sulků frontálních laloků. ',
    conclusion: 'Nápadnější atrofizace frontálních laloků. ',
  },
  {
    value: 2,
    text: 'parietální laloky',
    description:
      'Nápadnější redukce šíře gyrů a tak rozšíření sulků parietálních laloků. ',
    conclusion: 'Nápadnější atrofizace parietálních laloků. ',
  },
  {
    value: 3,
    text: 'okcipitální laloky',
    description:
      'Nápadnější redukce šíře gyrů a tak rozšíření sulků okcipitálních laloků. ',
    conclusion: 'Nápadnější atrofizace okcipitálních laloků. ',
  },
  {
    value: 4,
    text: 'temporální laloky',
    description:
      'Nápadnější redukce šíře gyrů a tak rozšíření sulků temporálních laloků. ',
    conclusion: 'Nápadnější atrofizace temporálních laloků. ',
  },
  {
    value: 5,
    text: 'frontální a temporální',
    description:
      'Výrazná redukce šíře gyrů a tak rozšíření sulků frontálních a temporálních laloků. ',
    conclusion: 'Nápadnější atrofizace frontálních a temporálních laloků. ',
  },
  {
    value: 6,
    text: 'frontální a parietální',
    description:
      'Výrazná redukce šíře gyrů a tak rozšíření sulků frontálních a parietálních laloků. ',
    conclusion: 'Nápadnější atrofizace frontálních a parietálních laloků. ',
  },
  {
    value: 7,
    text: 'PSP',
    description:
      'Atrofizace mezencefala při zachovalém objemu pontu (nízký poměr mezencefala k pontu - low midbrain to pons ratio). ',
    conclusion:
      'Morfologické změny mozkového kmene, které mohou odpovídat PSP (progressive supranuclear palsy). ',
  },
];

// Ostatní

export const aPituitary = [
  {
    value: 0,
    text: 'normální nález',
    description: 'S ohledem na věk normální velikost hypofýzy. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'partially empty sella',
    description:
      'Turecké sedlo je z velké části vyplněno signálem likvoru, na jeho dně je zmenšená hypofýza, kraniokaudálně velikosti min. 3mm. ',
    conclusion: 'Partially empty sella. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'empty sella',
    description:
      'Turecké sedlo je z velké části vyplněno signálem likvoru, na jeho dně je výrazně zmenšená hypofýza, kraniokaudálně velikosti 2mm nebo méně. ',
    conclusion: 'Empty sella. ',
    color: yellow,
  },
  {
    value: 3,
    text: 'Rathke c.',
    description:
      'Na rozhraní adeno a neurohypofýzy ohraničená kolekce tekutiny velikosti ',
    conclusion: 'Cysta Rathkeho výchlipky',
    color: orange,
  },
];

export const aMastoid = [
  {
    value: 0,
    text: 'normální',
    description: 'jsou vzdušné. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírné zm.',
    description: 's malým podílem vyšší SI na T2 a FLAIR. ',
    conclusion: 's malým množstvím tekutiny či slizničních změn. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední zm.',
    description: 's větším podílem vyšší SI na T2 a FLAIR. ',
    conclusion: 'zčásti vyplněny tekutinou či slizničními změnami. ',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké zm.',
    description: 's rozsáhlým podílem vyšší SI na T2 a FLAIR. ',
    conclusion: 'z velké části vyplněny tekutinou či slizničními změnami. ',
    color: red,
  },
];

// Variety

export const aPerivascSpaces = [
  {
    value: 0,
    text: 'přiměřené šíře',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'rozšířené centrálně',
    description:
      'Varietně rozšířené perivaskulární prostory v oblasti bazálních ganglií bilat. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'rozšířené difuzně',
    description: 'Difuzně varietně rozšířené perivaskulární prostory. ',
    conclusion: '',
    color: yellow,
  },
];

export const aExtraaxial = [
  {
    value: 0,
    text: 'bez nálezu',
  },
  {
    value: 1,
    text: 'frontálně vpravo',
  },
  {
    value: 2,
    text: 'frontálně vlevo',
  },
  {
    value: 3,
    text: 'temporálně vpravo',
  },
  {
    value: 4,
    text: 'temporálně vlevo',
  },
  {
    value: 5,
    text: 'parietálně vpravo',
  },
  {
    value: 6,
    text: 'parietálně vlevo',
  },
  {
    value: 7,
    text: 'okcipitálně vpravo',
  },
  {
    value: 8,
    text: 'okcipitálně vlevo',
  },
];

export const aSeptumPellucidum = [
  {
    value: 0,
    text: 'normální',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: 'cavum septi pellucidi',
    description: 'Cavum septi pellucidi. ',
    conclusion: '',
  },
  {
    value: 2,
    text: 'cavum veli interpositi',
    description: 'Cavum septi pellucidi. ',
    conclusion: '',
  },
  {
    value: 3,
    text: 'cavum vergae',
    description: 'Cavum vergae. ',
    conclusion: '',
  },
  {
    value: 4,
    text: 'cavum s. pell. a vergae',
    description: 'Cavum s. pellucidi a vergae. ',
    conclusion: '',
  },
  {
    value: 5,
    text: 'cav. s. pell. a veli inter.',
    description: '',
    conclusion: '',
  },
];

export const aCalvarium = [
  {
    value: 0,
    text: 'bez nálezu',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hyperostosis front. int.',
    description:
      'Nepravidelné zesílení vnitřní kontury kalvy frontálně bilat., signálu kosti - hyperostosis frontalis interna. ',
    conclusion: '',
    color: yellow,
  },
];

export const aLymbic = [
  {
    value: 0,
    text: 'bez nálezu',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'cysty hipokamp. sulku',
    description:
      'Drobné reziduální cysty hipokampálního sulku (častá varieta). ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: '<< cysta choroid. fisury',
    description: 'Cysta pravostranné choroidální fissury (varieta). ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 3,
    text: 'cysta choroid. fisury >>',
    description: 'Cysta levostranné choroidální fissury (varieta). ',
    conclusion: '',
    color: yellow,
  },
];

export const aIntraaxial = [
  {
    value: 0,
    text: 'bez nálezu',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: 'pravý frontální lalok',
    description: 'pravého frontálního laloku. ',
    conclusion: 'v pravém frontálním laloku. ',
  },
  {
    value: 2,
    text: 'levý frontální lalok',
    description: 'levého frontálního laloku. ',
    conclusion: 'levého frontálním laloku. ',
  },
  {
    value: 3,
    text: 'pravý parietální lalok',
    description: 'pravého parietálního laloku. ',
    conclusion: 'v pravém parietálním laloku. ',
  },
  {
    value: 4,
    text: 'levý parietální lalok',
    description: 'levého parietálního laloku. ',
    conclusion: 'v levém parietálním laloku. ',
  },
  {
    value: 5,
    text: 'pravý temporální lalok',
    description: 'pravého temporálního laloku. ',
    conclusion: 'v pravém temporálním laloku. ',
  },
  {
    value: 6,
    text: 'levý temporální lalok',
    description: 'levého temporálního laloku. ',
    conclusion: 'v levém temporálním laloku. ',
  },
  {
    value: 7,
    text: 'pravý okcipitální lalok',
    description: 'pravého okcipitálního laloku. ',
    conclusion: 'v pravém okcipitálním laloku. ',
  },
  {
    value: 8,
    text: 'levý okcipitální lalok',
    description: 'levého okcipitálního laloku. ',
    conclusion: 'v levém okcipitálním laloku. ',
  },
  {
    value: 9,
    text: 'pravá mozečková hemisféra',
    description: 'pravé mozečkové hemisféry. ',
    conclusion: 'v pravé mozečkové hemisféře. ',
  },
  {
    value: 10,
    text: 'levá mozečková hemisféra',
    description: 'levé mozečkové hemisféry. ',
    conclusion: 'v levé mozečkové hemisféře. ',
  },
];

export const aSinusFR = [
  {
    value: 0,
    text: 'normální',
    description: 'pravého frontálního',
    label: 'FR',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'pravého frontálního',
    label: 'FR',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'pravého frontálního',
    label: 'FR',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'pravého frontálního',
    label: 'FR',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'pravého frontálního',
    label: 'FR',
    color: grey,
  },
];

export const aSinusFL = [
  {
    value: 0,
    text: 'normální',
    description: 'levého frontálního',
    label: 'FL',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'levého frontálního',
    label: 'FL',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'levého frontálního',
    label: 'FL',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'levého frontálního',
    label: 'FL',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'levého frontálního',
    label: 'FL',
    color: grey,
  },
];

export const aSinusMR = [
  {
    value: 0,
    text: 'normální',
    description: 'pravého maxilárního',
    label: 'MR',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'pravého maxilárního',
    label: 'MR',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'pravého maxilárního',
    label: 'MR',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'pravého maxilárního',
    label: 'MR',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'pravého maxilárního',
    label: 'MR',
    color: grey,
  },
  {
    value: 5,
    text: 'atelektáza',
    description: 'pravého maxilárního',
    label: 'MR',
    color: grey,
  },
  {
    value: 6,
    text: 'ret. cysta',
    description: 'pravého maxilárního',
    label: 'MR',
    color: grey,
  },
  {
    value: 7,
    text: 'ret. cysty',
    description: 'pravého maxilárního',
    label: 'MR',
    color: grey,
  },
];

export const aSinusML = [
  {
    value: 0,
    text: 'normální',
    description: 'levého maxilárního',
    label: 'ML',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'levého maxilárního',
    label: 'ML',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'levého maxilárního',
    label: 'ML',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'levého maxilárního',
    label: 'ML',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'levého maxilárního',
    label: 'ML',
    color: grey,
  },
  {
    value: 5,
    text: 'atelektáza',
    description: 'levého maxilárního',
    label: 'ML',
    color: grey,
  },
  {
    value: 6,
    text: 'ret. cysta',
    description: 'pravého maxilárního',
    label: 'MR',
    color: grey,
  },
  {
    value: 7,
    text: 'ret. cysty',
    description: 'pravého maxilárního',
    label: 'MR',
    color: grey,
  },
];

export const aSinusER = [
  {
    value: 0,
    text: 'normální',
    description: 'pravého etmoidálního',
    label: 'ER',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'pravého etmoidálního',
    label: 'ER',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'pravého etmoidálního',
    label: 'ER',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'pravého etmoidálního',
    label: 'ER',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'pravého etmoidálního',
    label: 'ER',
    color: grey,
  },
];

export const aSinusEL = [
  {
    value: 0,
    text: 'normální',
    description: 'levého etmoidálního',
    label: 'EL',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'levého etmoidálního',
    label: 'EL',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'levého etmoidálního',
    label: 'EL',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'levého etmoidálního',
    label: 'EL',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'levého etmoidálního',
    label: 'EL',
    color: grey,
  },
];

export const aSinusSR = [
  {
    value: 0,
    text: 'normální',
    description: 'pravého sfenoidálního',
    label: 'SR',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'pravého sfenoidálního',
    label: 'SR',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'pravého sfenoidálního',
    label: 'SR',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'pravého sfenoidálního',
    label: 'SR',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'pravého etmoidálního',
    label: 'SR',
    color: grey,
  },
];

export const aSinusSL = [
  {
    value: 0,
    text: 'normální',
    description: 'levého sfenoidálního',
    label: 'SL',
    color: green,
  },
  {
    value: 1,
    text: 'mírné',
    description: 'levého sfenoidálního',
    label: 'SL',
    color: yellow,
  },
  {
    value: 2,
    text: 'střední',
    description: 'levého sfenoidálního',
    label: 'SL',
    color: orange,
  },
  {
    value: 3,
    text: 'těžké',
    description: 'levého sfenoidálního',
    label: 'SL',
    color: red,
  },
  {
    value: 4,
    text: 'aplázie',
    description: 'levého sfenoidálního',
    label: 'SL',
    color: grey,
  },
];

export const aArteriesVariety = [
  {
    value: 0,
    text: 'normal',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hypoplázie',
    description: 'Hypoplázie ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'aplázie',
    description: 'Aplázie ',
    conclusion: '',
    color: yellow,
  },
];

export const aSeptumNasi = [
  {
    value: 0,
    text: 'v ose',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<< doprava',
    description: 'Deviace septum nasi doprava. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'doleva >>',
    description: 'Deviace septum nasi doleva. ',
    conclusion: '',
    color: yellow,
  },
];

export const aConchaBullosa = [
  {
    value: 0,
    text: 'není',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<< vpravo',
    description: 'Concha bullosa vpravo. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'vlevo >>',
    description: 'Concha bullosa vlevo. ',
    conclusion: '',
    color: yellow,
  },
];

export const aDemyelinSupra = [
  {
    value: 0,
    text: 'stac. ložiska',
    description:
      'Oproti minule bez přesvědčivé progrese velikosti či počtu ložisek v supratentoriální bílé hmotě. ',
    conclusion: 'Stac. supratentoriální ložiska. ',
    color: green,
  },
  {
    value: 1,
    text: 'bez ložisek',
    description: 'Bez přesvědčivých ložisek v supratentoriální bílé hmotě. ',
    conclusion: 'Bez supratentoriálních ložisek. ',
    color: green,
  },
];

export const aDemyelinSupraFilled = [
  {
    value: 0,
    text: 'jinak stac. ložiska',
    description:
      'Jinak oproti minule bez přesvědčivé progrese velikosti či počtu ložisek v supratentoriální bílé hmotě. ',
    conclusion:
      'Jinak oproti minule bez přesvědčivé progrese velikosti či počtu ložisek v supratentoriální bílé hmotě. ',
    color: green,
  },
  {
    value: 1,
    text: 'jinak bez ložisek',
    description:
      'Jinak bez přesvědčivých dalších ložisek v supratentoriální bílé hmotě. ',
    conclusion:
      'Jinak bez přesvědčivých dalších ložisek v supratentoriální bílé hmotě. ',
    color: green,
  },
];

export const aDemyelinInfra = [
  {
    value: 0,
    text: 'stac. ložiska',
    description: 'Stac. ložiska v infratentoriální bílé hmotě. ',
    conclusion: 'Stac. ložiska v infratentoriální bílé hmotě. ',
    color: green,
  },
  {
    value: 1,
    text: 'bez ložisek',
    description: 'Bez přesvědčivých ložisek v infratentoriální bílé hmotě. ',
    conclusion: 'Bez přesvědčivých ložisek v infratentoriální bílé hmotě. ',
    color: green,
  },
];

export const aDemyelinInfraFilled = [
  {
    value: 0,
    text: 'jinak stac. ložiska',
    description: 'Jinak stac. ložiska v infratentoriální bílé hmotě. ',
    conclusion: 'Jinak stac. ložiska v infratentoriální bílé hmotě. ',
    color: green,
  },
  {
    value: 1,
    text: 'jinak bez ložisek',
    description: 'Jinak bez ložisek v infratentoriální bílé hmotě. ',
    conclusion: 'Jinak bez ložisek v infratentoriální bílé hmotě. ',
    color: green,
  },
];

export const aDemyelinIntramed = [
  {
    value: 0,
    text: 'stac. ložiska',
    description: 'Stac. intramedulární ložiska. ',
    conclusion: 'Stac. intramedulární ložiska. ',
    color: green,
  },
  {
    value: 1,
    text: 'bez ložisek',
    description: 'Bez přesvědčivých intramedulárních ložisek. ',
    conclusion: 'Bez přesvědčivých intramedulárních ložisek. ',
    color: green,
  },
];

export const aDemyelinIntramedFilled = [
  {
    value: 0,
    text: 'jinak stac. ložiska',
    description: 'Jinak stac. intramedulární ložiska. ',
    conclusion: 'Jinak stac. intramedulární ložiska. ',
    color: green,
  },
  {
    value: 1,
    text: 'jinak bez ložisek',
    description: 'Jinak bez přesvědčivých intramedulárních ložisek. ',
    conclusion: 'Jinak bez přesvědčivých intramedulárních ložisek. ',
    color: green,
  },
];

export const aLatVentAsymetry = [
  {
    value: 0,
    text: 'bez asymetrie',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<< větší pravá',
    description: 'Varietně asymetricky větší pravá postranní komora. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'větší levá >>',
    description: 'Varietně asymetricky větší levá postranní komora. ',
    conclusion: '',
    color: yellow,
  },
];

export const aVDN = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '1.st.',
    description: '',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: '2.st.',
    description: '',
    conclusion: '',
    color: yellow,
  },
  {
    value: 3,
    text: '3.st.',
    description: '',
    conclusion: '',
    color: yellow,
  },
];
