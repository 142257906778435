import { green, yellow, orange, red } from '../scripts/GeneralArrays';

export const aDistance = [
  {
    value: 0,
    text: '0cm',
  },
  {
    value: 1,
    text: '1cm',
  },
  {
    value: 2,
    text: '2cm',
  },
  {
    value: 3,
    text: '3cm',
  },
  {
    value: 4,
    text: '4cm',
  },
  {
    value: 5,
    text: '5cm',
  },
  {
    value: 6,
    text: '6cm',
  },
  {
    value: 7,
    text: '7cm',
  },
  {
    value: 8,
    text: '8cm',
  },
  {
    value: 9,
    text: '9cm',
  },
  {
    value: 10,
    text: '10cm',
  },
  {
    value: 11,
    text: '11cm',
  },
  {
    value: 12,
    text: '12cm',
  },
  {
    value: 13,
    text: '13cm',
  },
  {
    value: 14,
    text: '14cm',
  },
  {
    value: 15,
    text: '15cm',
  },
];

export const aMRF = [
  {
    value: 0,
    text: 'bezpečná vzdálenost',
    description:
      'MRF- (mezorektální fascie je bezpečně vzdálena od nádorové invaze)',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'vzdálenost min. 5mm',
    description: 'MRF- (min. 5mm mezi nádorovou infiltrací a MRF)',
    conclusion: '',
    color: green,
  },
  {
    value: 2,
    text: 'vzdálenost min. 4mm',
    description: 'MRF- (min. 4mm mezi nádorovou infiltrací a MRF)',
    conclusion: '',
    color: yellow,
  },
  {
    value: 3,
    text: 'vzdálenost min. 3mm',
    description: 'MRF- (min. 3mm mezi nádorovou infiltrací a MRF)',
    conclusion: '',
    color: orange,
  },
  {
    value: 4,
    text: 'vzdálenost min. 2mm',
    description: 'MRF- (min. 2mm mezi nádorovou infiltrací a MRF)',
    conclusion: '',
    color: orange,
  },
  {
    value: 5,
    text: 'vzdálenost min. 1mm',
    description: 'MRF- (min. 1mm mezi nádorovou infiltrací a MRF)',
    conclusion: '',
    color: orange,
  },
  {
    value: 6,
    text: '<1mm nebo invaze (MRF+)',
    description: 'MRF+ (nádorová infiltrace mezorektální fascie)',
    conclusion: '',
    color: red,
  },
];

export const aTstage = [
  {
    value: 0,
    text: 'cT2 - pouze ve stěně',
    description: 'nádor pouze intramurálně',
    conclusion: 'cT2',
    color: green,
  },
  {
    value: 1,
    text: 'cT3a - < 1mm invaze tuku',
    description: 'invaze mezorektálního tuku do hloubky < 1mm',
    conclusion: 'cT3a',
    color: yellow,
  },
  {
    value: 2,
    text: 'cT3b - 1-5mm invaze tuku',
    description: 'invaze mezorektálního tuku do hloubky max. 5mm',
    conclusion: 'cT3b',
    color: orange,
  },
  {
    value: 3,
    text: 'cT3c - 5-15mm invaze tuku',
    description: 'invaze mezorektálního tuku do hloubky max. 15mm',
    conclusion: 'cT3c',
    color: orange,
  },
  {
    value: 4,
    text: 'cT3d - > 15mm invaze tuku',
    description: 'invaze mezorektálního tuku do hloubky > 15mm',
    conclusion: 'cT3d',
    color: red,
  },
  {
    value: 5,
    text: 'T4a - invaze periton. recesu',
    description: 'infiltruje peritoneální recesus',
    conclusion: 'T4a',
    color: red,
  },
  {
    value: 6,
    text: 'T4b - invaze orgánu',
    description: 'infiltruje okolní orgány',
    conclusion: 'T4b',
    color: red,
  },
];

export const aTumorType = [
  {
    value: 0,
    text: 'semicirkulární',
    description: 'semicirkulární ',
    color: green,
  },
  {
    value: 1,
    text: 'cirkulární',
    description: 'cirkulární ',
    color: green,
  },
  {
    value: 2,
    text: 'miskovitý',
    description: 'miskovitý ',
    color: green,
  },
  {
    value: 3,
    text: 'polypoidní',
    description: 'polypoidní ',
    color: green,
  },
];

export const aPeritReflexLevel = [
  {
    value: 0,
    text: 'tumor nad perit. reflexí',
    description: 'nad ',
    color: green,
  },
  {
    value: 1,
    text: 'tumor v úrovni reflexe',
    description: 'v úrovni ',
    color: green,
  },
  {
    value: 2,
    text: 'tumor pod úrovní reflexe',
    description: 'pod ',
    color: green,
  },
];

export const aTumorContent = [
  {
    value: 0,
    text: 'solidní',
    description: 'solidní ',
    color: green,
  },
  {
    value: 1,
    text: 'mucinózní',
    description: 'mucinózní ',
    color: green,
  },
  {
    value: 2,
    text: 'smíšený',
    description: 'smíšený ',
    color: green,
  },
];

export const aTumorStart = [
  {
    value: 0,
    text: 'č. 12',
  },
  {
    value: 1,
    text: 'č. 1',
  },
  {
    value: 2,
    text: 'č. 2',
  },
  {
    value: 3,
    text: 'č. 3',
  },
  {
    value: 4,
    text: 'č. 4',
  },
  {
    value: 5,
    text: 'č. 5',
  },
  {
    value: 6,
    text: 'č. 6',
  },
  {
    value: 7,
    text: 'č. 7',
  },
  {
    value: 8,
    text: 'č. 8',
  },
  {
    value: 9,
    text: 'č. 9',
  },
  {
    value: 10,
    text: 'č. 10',
  },
  {
    value: 11,
    text: 'č. 11',
  },
];

export const aTumorEnd = [
  {
    value: 0,
    text: 'č. 12',
  },
  {
    value: 1,
    text: 'č. 1',
  },
  {
    value: 2,
    text: 'č. 2',
  },
  {
    value: 3,
    text: 'č. 3',
  },
  {
    value: 4,
    text: 'č. 4',
  },
  {
    value: 5,
    text: 'č. 5',
  },
  {
    value: 6,
    text: 'č. 6',
  },
  {
    value: 7,
    text: 'č. 7',
  },
  {
    value: 8,
    text: 'č. 8',
  },
  {
    value: 9,
    text: 'č. 9',
  },
  {
    value: 10,
    text: 'č. 10',
  },
  {
    value: 11,
    text: 'č. 11',
  },
];

export const aLN = [
  {
    value: 0,
    text: 'normální',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'zvětšené',
    description: 'zvětšené',
    conclusion: 'zvětšené',
    color: red,
  },
];

export const aLNsize = [
  {
    value: 0,
    text: 'příčně <5mm',
    description: 'Mezorektální lymfatická uzlina v krátké ose menší než 5mm',
  },
  {
    value: 1,
    text: 'příčně 5-9mm',
    description:
      'Mezorektální lymfatická uzlina v krátké ose velikostí mezi 5 až 9mm',
  },
  {
    value: 2,
    text: 'příčně >9mm',
    description: 'Mezorektální lymfatická uzlina v krátké ose větší než 9mm',
  },
];

export const aLNcount = [
  {
    value: 0,
    text: '1',
    description: '1',
    conclusion: 'jedna ',
    color: orange,
  },
  {
    value: 1,
    text: '2',
    description: '2',
    conclusion: 'dvě ',
    color: orange,
  },
  {
    value: 2,
    text: '3',
    description: '3',
    conclusion: 'tři ',
    color: orange,
  },
  {
    value: 3,
    text: '4',
    description: '4',
    conclusion: 'čtyři ',
    color: orange,
  },
  {
    value: 4,
    text: '5',
    description: '5',
    conclusion: 'pět ',
    color: orange,
  },
  {
    value: 5,
    text: '6',
    description: '6',
    conclusion: '6',
    color: orange,
  },
  {
    value: 6,
    text: '7',
    description: '7',
    conclusion: '7',
    color: orange,
  },
];

export const aAnalCanalInvasion = [
  {
    value: 0,
    text: 'bez invaze',
    description: 'bez infiltrace análního kanálu',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'horní třetina',
    description: 'invaze horní třetiny análního kanálu',
    conclusion: 'invaze horní třetiny análního kanálu',
    color: red,
  },
  {
    value: 2,
    text: 'střední třetina',
    description: 'invaze střední třetiny análního kanálu',
    conclusion: 'invaze střední třetiny análního kanálu',
    color: red,
  },
  {
    value: 3,
    text: 'dolní třetina',
    description: 'invaze dolní třetiny análního kanálu',
    conclusion: 'invaze dolní třetiny análního kanálu',
    color: red,
  },
];
