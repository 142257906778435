import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Custom scripts
import {
  aExams,
  aLiverParenchyma,
  aLiverSize,
  aGBwall,
  aGBcontent,
  aGBsize,
  aBiliaryEHSize,
  aBiliaryIHSize,
  aSpleenSize,
  aKidneyParenchyma,
  aKidneyKPS,
  aPancreasParenchyma,
  aPancreasDuctus,
  aAppendix,
  aAppendixPos,
  aPelvisOther,
  aSigmoid,
  aUrinaryBladder,
  aIntraabdominalFluid,
  aLiverDoppler,
} from './US_abdomen_arrays';
import { generateReport } from './US_abdomen_functions';

// General scripts
import {
  contentWidth,
  aContrastMR,
  aContrastMRamount,
  aCardColors,
  aTrueFalse,
  aSizemm,
} from '../scripts/GeneralArrays';
import {} from '../scripts/GeneralFunctions';

// Custom components
import ExamHeader from '../components/ExamHeader';
import CustomCards from '../components/CustomCards';
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';
import Report from '../components/Report';

// 3rd party components
import { Form, Row, Col, Select, Input } from 'antd';
import { debounce } from 'lodash';
import ButtonUpDown from '../components/ButtonUpDown';
const { Option } = Select;

class US_abdomen extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      liverParenchyma: 0,
      liverSize: 0,
      liverDoppler: 0,
      GBsurgery: false,
      GBwall: 0,
      GBcontent: 0,
      GBconcrementSize: '',
      GBsize: 0,
      biliaryEHSize: 0,
      biliaryIHSize: 0,
      biliaryAir: false,
      spleenSize: 0,
      kidneyRparenchyma: 0,
      kidneyRKPS: 0,
      kidneyLparenchyma: 0,
      kidneyLKPS: 0,
      pancreasParenchyma: 0,
      pancreasDuctus: 0,
      appendix: 0,
      appendixSize: 10,
      appendixPos: 0,
      sigmoid: 0,
      sigmoidWall: 10,
      sigmoidDiverticles: false,
      urinaryBladder: 0,
      intraabdominalFluid: 0,
      pelvisOther: [],
      valueNum: 0,
      valueText: '',
      valueBoolean: 0,
    },
    lesions: [],
  };

  initialState = cloneDeep(this.state);

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = value => {
    let valueNum = value;
    let valueText = '';
    let valueBoolean = false;

    let newRecord = {
      valueNum: valueNum,
      valueText: valueText,
      valueBoolean: valueBoolean,
    };

    this.setState(prevState => ({
      lesions: [...prevState.lesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const { protocol } = this.props;
    const myContent1 = [
      {
        title: 'játra',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'velikost',
              input: (
                <ButtonUpDown
                  name='liverSize'
                  segment='general'
                  value={this.state.general.liverSize}
                  options={aLiverSize}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='liverParenchyma'
                  segment='general'
                  value={this.state.general.liverParenchyma}
                  options={aLiverParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'Doppler',
              input: (
                <ButtonUpDown
                  name='liverDoppler'
                  segment='general'
                  value={this.state.general.liverDoppler}
                  options={aLiverDoppler}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'žlučník a žlučové cesty',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'CHCE',
              input: (
                <ButtonTrueFalse
                  name='GBsurgery'
                  segment='general'
                  value={this.state.general.GBsurgery}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.general.GBsurgery ? '' : 'obsah žlučníku',
              input: !this.state.general.GBsurgery ? (
                <>
                  <Row>
                    <Col flex='auto'>
                      <Row>
                        <Col flex='auto'>
                          <ButtonUpDown
                            name='GBcontent'
                            segment='general'
                            value={this.state.general.GBcontent}
                            options={aGBcontent}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Row>
                      {this.state.general.GBcontent >= 2 ? (
                        <Row>
                          <Col flex='auto'></Col>
                          <Col span={24}>
                            <Input
                              name='GBconcrementSize'
                              size='small'
                              suffix='mm'
                              placeholder='velikost max. '
                              onChange={event =>
                                this.onChange({
                                  segment: 'general',
                                  name: 'GBconcrementSize',
                                  value: event.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </>
              ) : (
                ''
              ),
            },
          ],
          [
            {
              label: this.state.general.GBsurgery ? '' : 'stěna žlučníku',
              input: !this.state.general.GBsurgery ? (
                <ButtonUpDown
                  name='GBwall'
                  segment='general'
                  value={this.state.general.GBwall}
                  options={aGBwall}
                  onChange={this.onChange}
                />
              ) : (
                ''
              ),
            },
          ],
          [
            {
              label: this.state.general.GBsurgery ? '' : 'náplň žlučn.',
              input: !this.state.general.GBsurgery ? (
                <ButtonUpDown
                  name='GBsize'
                  segment='general'
                  value={this.state.general.GBsize}
                  options={aGBsize}
                  onChange={this.onChange}
                />
              ) : (
                ''
              ),
            },
          ],
          [
            {
              label: 'd. hepatochol.',
              input: (
                <ButtonUpDown
                  name='biliaryEHSize'
                  segment='general'
                  value={this.state.general.biliaryEHSize}
                  options={aBiliaryEHSize}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'šíře IH žl. cest',
              input: (
                <ButtonUpDown
                  name='biliaryIHSize'
                  segment='general'
                  value={this.state.general.biliaryIHSize}
                  options={aBiliaryIHSize}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'aerobilie',
              input: (
                <ButtonTrueFalse
                  name='biliaryAir'
                  segment='general'
                  value={this.state.general.biliaryAir}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'slezina',
        color: aCardColors.orange,
        rows: [
          [
            {
              label: 'velikost',
              input: (
                <ButtonUpDown
                  name='spleenSize'
                  segment='general'
                  value={this.state.general.spleenSize}
                  options={aSpleenSize}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: '',
              input: '',
            },
          ],
        ],
      },
    ];

    const myContent2 = [
      {
        title: 'pravá ledvina',
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='kidneyRparenchyma'
                  segment='general'
                  value={this.state.general.kidneyRparenchyma}
                  options={aKidneyParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'KPS',
              input: (
                <ButtonUpDown
                  name='kidneyRKPS'
                  segment='general'
                  value={this.state.general.kidneyRKPS}
                  options={aKidneyKPS}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'pankreas a střední retroperitoneum',
        color: aCardColors.green,
        rows: [
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='pancreasParenchyma'
                  segment='general'
                  value={this.state.general.pancreasParenchyma}
                  options={aPancreasParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'ductus pancr.',
              input: (
                <ButtonUpDown
                  name='pancreasDuctus'
                  segment='general'
                  value={this.state.general.pancreasDuctus}
                  options={aPancreasDuctus}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'levá ledvina',
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='kidneyLparenchyma'
                  segment='general'
                  value={this.state.general.kidneyLparenchyma}
                  options={aKidneyParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'KPS',
              input: (
                <ButtonUpDown
                  name='kidneyLKPS'
                  segment='general'
                  value={this.state.general.kidneyLKPS}
                  options={aKidneyKPS}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    const myContent3 = [
      {
        title: 'appendix',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'appendix',
              input: (
                <ButtonUpDown
                  name='appendix'
                  segment='general'
                  value={this.state.general.appendix}
                  options={aAppendix}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.general.appendix > 2 ? 'šíře' : '',
              input:
                this.state.general.appendix > 2 ? (
                  <ButtonUpDown
                    name='appendixSize'
                    segment='general'
                    value={this.state.general.appendixSize}
                    options={aSizemm}
                    onChange={this.onChange}
                  />
                ) : (
                  ''
                ),
            },
          ],
          [
            {
              label: this.state.general.appendix > 2 ? 'poloha' : '',
              input:
                this.state.general.appendix > 2 ? (
                  <ButtonUpDown
                    name='appendixPos'
                    segment='general'
                    value={this.state.general.appendixPos}
                    options={aAppendixPos}
                    onChange={this.onChange}
                  />
                ) : (
                  ''
                ),
            },
          ],
        ],
      },
      {
        title: 'malá pánev',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'moč. měchýř',
              input: (
                <ButtonUpDown
                  name='urinaryBladder'
                  segment='general'
                  value={this.state.general.urinaryBladder}
                  options={aUrinaryBladder}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'ascites',
              input: (
                <ButtonUpDown
                  name='intraabdominalFluid'
                  segment='general'
                  value={this.state.general.intraabdominalFluid}
                  options={aIntraabdominalFluid}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'ostatní',
              input: (
                <DropDown
                  name='pelvisOther'
                  segment='general'
                  defaultValue={this.state.general.pelvisOther}
                  options={aPelvisOther}
                  onChange={this.onChange}
                  multiselect={true}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'sigma',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'sigma',
              input: (
                <>
                  <ButtonUpDown
                    name='sigmoid'
                    segment='general'
                    value={this.state.general.sigmoid}
                    options={aSigmoid}
                    onChange={this.onChange}
                  />
                </>
              ),
            },
          ],
          [
            {
              label: this.state.general.sigmoid > 1 ? 'šíře stěny' : '',
              input:
                this.state.general.sigmoid > 1 ? (
                  <ButtonUpDown
                    name='sigmoidWall'
                    segment='general'
                    value={this.state.general.sigmoidWall}
                    options={aSizemm}
                    onChange={this.onChange}
                  />
                ) : (
                  ''
                ),
            },
          ],
          [
            {
              label: this.state.general.sigmoid > 1 ? 'divertikly' : '',
              input:
                this.state.general.sigmoid > 1 ? (
                  <ButtonTrueFalse
                    name='sigmoidDiverticles'
                    segment='general'
                    value={this.state.general.sigmoidDiverticles}
                    options={aTrueFalse}
                    onChange={this.onChange}
                  />
                ) : (
                  ''
                ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
        <CustomCards content={myContent3} />
      </Form>
    );
  }
}

export default US_abdomen;
