import { green, yellow, orange, red } from "../scripts/GeneralArrays";

export const aProstateSize = [
  {
    value: 0,
    text: "normální",
    description: "Prostata normální velikosti. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "Prostata mírně zvětšena zbytněním přechodové zóny. ",
    conclusion: "Prostata mírně zvětšena. ",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "Prostata zvětšena zbytněním přechodové zóny. ",
    conclusion: "Prostata zvětšena. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "Prostata výrazně zvětšena zbytněním přechodové zóny. ",
    conclusion: "Prostata výrazně zvětšena. ",
    color: red,
  },
];

export const aUterusGeneral = [
  {
    value: 0,
    text: "normální",
    description: "věku přiměřené velikosti. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "nedif.",
    description: "není přesvědčivě diferencovatelná. ",
    conclusion: "St.p. hysterektomii. ",
    color: yellow,
  },
  {
    value: 2,
    text: "myom",
    description: "obsahuje v myometriu na T2 hyposignální ložisko. ",
    conclusion: "Myom dělohy. ",
    color: orange,
  },
  {
    value: 3,
    text: "myomy",
    description: "obsahuje  myometriu na T2 hyposignální ložiska. ",
    conclusion: "Myomy dělohy. ",
    color: orange,
  },
];

export const aUterusPosition = [
  {
    value: 0,
    text: "anteverze",
    description: "v anteverzi, ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "retroverze",
    description: "v retroverzi, ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "dextroverze",
    description: "v dextroverzi, ",
    conclusion: "",
    color: green,
  },
  {
    value: 3,
    text: "sinistropozice",
    description: "v sinistropozici, ",
    conclusion: "",
    color: green,
  },
];

export const aOvarium = [
  {
    value: 0,
    text: "normální",
    description: "s přiměřeným nálezem. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "nedif.",
    description: "není přesvědčivě diferencovatelné. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "cysta",
    description:
      "obsahuje tenkou stěnou ohraničenou kolekci signálu tekutiny. ",
    conclusion: "s cystou. ",
    color: orange,
  },
  {
    value: 3,
    text: "cysty",
    description: "obsahuje tenkou stěnou ohraničené kolekce signálu tekutiny. ",
    conclusion: "s cystami. ",
    color: orange,
  },
];

export const aAscites = [
  {
    value: 0,
    text: "bez volné tekutiny",
    description: "V peritoneální dutině bez volné tekutiny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "malý ascites",
    description:
      "V peritoneální dutině na dně pánve malé množství volné tekutiny. ",
    conclusion: "Malý ascites. ",
    color: yellow,
  },
  {
    value: 2,
    text: "středně velký ascites",
    description:
      "V oblasti pánve v peritoneální dutině volná tekutina mezi kličkami střevními. ",
    conclusion: "Středně velký ascites. ",
    color: orange,
  },
  {
    value: 3,
    text: "velký ascites",
    description:
      "V oblasti pánve v peritoneální dutině velké množství volné tekutiny mezi kličkami střevními. ",
    conclusion: "Velký ascites. ",
    color: red,
  },
];

export const aLimitations = [
  {
    value: 0,
    text: "bez limitace",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně rozhýbané",
    description:
      "Hodnotitelnost některých sekvencí limitována pohybovými artefakty. ",
    conclusion: "",
    color: orange,
  },
  {
    value: 2,
    text: "výrazně rozhýbané",
    description:
      "Hodnotitelnost některých sekvencí výrazně limitována pohybovými artefakty. ",
    conclusion: "",
    color: red,
  },
];

export const aNormalFindings = [
  {
    value: 0,
    text: "ukázat",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "neukázat",
    description: "",
    conclusion: "",
    color: orange,
  },
];

export const aLNsize = [
  {
    value: 0,
    text: "příčně <5mm",
    description: "Mezorektální lymfatická uzlina v krátké ose menší než 5mm",
  },
  {
    value: 1,
    text: "příčně 5-9mm",
    description:
      "Mezorektální lymfatická uzlina v krátké ose velikostí mezi 5 až 9mm",
  },
  {
    value: 2,
    text: "příčně >9mm",
    description: "Mezorektální lymfatická uzlina v krátké ose větší než 9mm",
  },
];

export const aLN = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční",
    description: "hraniční velikosti",
    conclusion: "hraniční velikosti",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšené",
    description: "zvětšené",
    conclusion: "zvětšené",
    color: red,
  },
];

export const aLNcount = [
  {
    value: 0,
    text: "1",
    description: "1",
    conclusion: "jedna ",
    color: orange,
  },
  {
    value: 1,
    text: "2",
    description: "2",
    conclusion: "dvě ",
    color: orange,
  },
  {
    value: 2,
    text: "3",
    description: "3",
    conclusion: "tři ",
    color: orange,
  },
  {
    value: 3,
    text: ">3",
    description: ">3",
    conclusion: "více než 3 ",
    color: orange,
  },
];
