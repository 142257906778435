// Custom scripts
import {
  aExams,
  aLiverParenchyma,
  aLiverSize,
  aLiverLesions,
  aLiverSegments,
  aGB,
  aBiliaryTract,
} from "./MR_liver_arrays";

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
  aSizemm,
  aSizecm,
} from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Játra a žlučové cesty

  const {
    liverSize,
    liverParenchyma,
    otherLiverDescription,
    otherLiverConclusion,
    GB,
    biliaryTract,
    otherBiliaryDescription,
    otherBiliaryConclusion,
  } = props.general;
  const { liverLesions } = props;

  let liverLesionsDescription = "";
  let liverLesionsConclusion = "";

  let arrayCysts = liverLesions.filter((item) => item.lesion === 0);
  let arrayHemangiomas = liverLesions.filter((item) => item.lesion === 1);
  let arrayFNHs = liverLesions.filter((item) => item.lesion === 2);
  let arraySteatosis = liverLesions.filter((item) => item.lesion === 3);
  let arrayNonSteatosis = liverLesions.filter((item) => item.lesion === 4);
  let arrayMetastasis = liverLesions.filter((item) => item.lesion === 5);

  let aLesions = [
    arrayMetastasis,
    arrayFNHs,
    arrayHemangiomas,
    arraySteatosis,
    arrayNonSteatosis,
    arrayCysts,
  ];

  // Popis ložisek jaterních
  if (liverLesions.length > 0) {
    liverLesions.map(
      (item) =>
        (liverLesionsDescription +=
          aLiverSegments[item.segment].description +
          aLiverLesions[item.lesion].description +
          (item.size !== "" ? ", velikosti " + item.size + "mm" : "") +
          ". ")
    );
  }

  // Závěr ložisek jaterních
  if (
    aLesions
      .map((item) => item.length)
      .reduce(function (a, b) {
        return a + b;
      }, 0) > 0
  ) {
    aLesions.map((item) => {
      if (item.length > 0) {
        liverLesionsConclusion +=
          aLiverLesions[item[0].lesion].conclusion +
          "v " +
          formatArrayToText(
            item.map(
              (item) =>
                aLiverSegments[item.segment].conclusion +
                (item.size !== "" ? " (" + item.size + "mm)" : "")
            )
          ) +
          ". ";
      }
    });
  }

  /*if (liverLesions.length > 0) {
    liverLesions.map((item) => {
      return (liverLesionsDescription +=
        aLiverSegments[item.segment].description +
        aLiverLesions[item.lesion].description +
        "velikosti " +
        item.size +
        "mm. ");
    });
    liverLesions.map((item) => {
      return (liverLesionsConclusion +=
        aLiverLesions[item.lesion].conclusion +
        "(" +
        aLiverSegments[item.segment].text +
        ", " +
        item.size +
        "mm). ");
    });
  }*/

  // Ostatní

  const { otherCustomDescription, otherCustomConclusion } = props.general;

  let restOfDescription =
    "zobrazeném rozsahu ostatní orgány dutiny břišní normální velikosti, bez přesvědčivých ložiskových změn, bez zvětšených lymfatických uzlin, bez volné tekutiny intraabdominálně, na STIR bez přesvědčivých ložiskových zvýšení signálu kostní dřeně. ";

  importDescription1(
    aLiverSize[liverSize].description +
      aLiverParenchyma[liverParenchyma].description +
      (liverLesions.length > 0
        ? liverLesionsDescription +
          "Jinak bez přesvědčivých ložiskových změn jaterních. "
        : "Bez přesvědčivých ložiskových změn jaterních. ") +
      otherLiverDescription +
      aGB[GB].description +
      aBiliaryTract[biliaryTract].description +
      otherBiliaryDescription
  );
  importDescription1(
    otherCustomDescription +
      (otherCustomDescription === "" ? "V " : "Jinak v ") +
      restOfDescription
  );

  importConclusion1(
    (liverLesions.length > 0 ? liverLesionsConclusion : "") +
      aLiverSize[liverSize].conclusion +
      aLiverParenchyma[liverParenchyma].conclusion +
      otherLiverConclusion +
      (liverSize + liverParenchyma + liverLesions.length > 0 ||
      otherLiverConclusion !== ""
        ? "Jinak přiměřený nález na MR jater. "
        : "Přiměřený nález na MR jater. ") +
      aGB[GB].conclusion +
      aBiliaryTract[biliaryTract].conclusion +
      otherBiliaryConclusion +
      otherCustomConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
