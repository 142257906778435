// Custom scripts
import {
  aTumorType,
  aTumorContent,
  aTumorStart,
  aTumorEnd,
  aMRF,
  aTstage,
  aLNcount,
  aLN,
  aLNsize,
  aAnalCanalInvasion,
} from './MR_rectum_arrays';

// General scripts
import { indent, br } from '../scripts/GeneralArrays';
import { capitelizeFirstLetter } from '../scripts/GeneralFunctions';
import { formatArrayToText } from '../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //
  const {
    tumorType,
    tumorContent,
    tumorStart,
    tumorEnd,
    Tstage,
    invasionStart,
    invasionEnd,
    analCanalInvasion,
    MRF,
    vascularInvasion,
    ARADistance,
    tumorLength,
    LNparaaortic,
    LNiliacComR,
    LNiliacComL,
    LNiliacExtR,
    LNiliacExtL,
    LNiliacIntR,
    LNiliacIntL,
    LNobturatorR,
    LNobturatorL,
    LNupperRectal,
    LNmesorectal,
    LNparaaorticCount,
    LNiliacComRCount,
    LNiliacComLCount,
    LNiliacExtRCount,
    LNiliacExtLCount,
    LNiliacIntRCount,
    LNiliacIntLCount,
    LNobturatorRCount,
    LNobturatorLCount,
    LNupperRectalCount,
    LNmesorectalCount,
    LNcustomDescription,
    LNcustomConclusion,
    otherFindingsDescription,
    otherFindingsConclusion,
  } = props.general;
  const { LN } = props;
  const { indistinct, heterogenous, rounded } = props.LN;

  // Strukturovaný popis:
  let structuredReport = '';
  // Morfologie:

  let tumorMorphology =
    aTumorContent[tumorContent].description +
    aTumorType[tumorType].description +
    'nádor';
  importDescription1('Morfologie:\t\t' + tumorMorphology);

  // Místo:
  // - úroveň nádoru a vzdálenost od anorektální junkce
  let tumorLevel =
    ARADistance >= 0 && ARADistance < 5
      ? 'dolní rektum'
      : ARADistance >= 5 && ARADistance < 10
      ? 'střední rektum'
      : 'horní rektum';
  let tumorBegins = '';
  if (ARADistance === 0) {
    tumorBegins = 'od anorektální junkce bez invaze análního kanálu';
    if (analCanalInvasion === 3) {
      tumorBegins = 'od dolní třetiny análního kanálu';
      console.log('až 5cm od anorektální junkce');
    }
  }
  let anorectalDistance = ARADistance;
  structuredReport +=
    'Úroveň: \t\t' +
    tumorLevel +
    ', ' +
    anorectalDistance +
    'cm od anorektální junkce\n';
  importDescription1(
    'Úroveň: \t\t' +
      tumorLevel +
      ', ' +
      anorectalDistance +
      'cm od anorektální junkce'
  );

  // - podélná délka nádoru
  structuredReport += 'Délka nádoru: \t' + tumorLength + 'cm\n';
  importDescription1('Délka nádoru: \t' + tumorLength + 'cm');
  // - obvod nádoru
  let tumorCircumference =
    'Obvod nádoru: \t' +
    aTumorStart[tumorStart].text +
    ' až ' +
    aTumorEnd[tumorEnd].text +
    ' (vleže na zádech)';
  structuredReport += tumorCircumference;
  importDescription1(tumorCircumference);
  // předěl ____________________________________
  importDescription1(
    '--------------------------------------------------------------------------------------------------------------------'
  );

  // cT stage
  let tumorStage = 'cT stage:\t\t' + aTstage[Tstage].conclusion;
  tumorStage += ' (' + aTstage[Tstage].description + ')';
  structuredReport += tumorStage;
  importDescription1(tumorStage);

  // mezorektální fascie
  structuredReport += 'MRF:\t\t\t' + aMRF[MRF].description + '\n';
  importDescription1('MRF:\t\t\t' + aMRF[MRF].description);
  // EMVI
  structuredReport +=
    'EMVI:\t\t\tEMVI- (bez přesvědčivé nádorové cévní invaze)';
  importDescription1(
    'EMVI:\t\t\tEMVI- (bez přesvědčivé nádorové cévní invaze)'
  );
  //-----------------------------------------------------------------------------------------
  // cN-stage
  importDescription1(
    '--------------------------------------------------------------------------------------------------------------------'
  );

  console.log(structuredReport);
  // tumor rekta obecně

  let tumorGeneralDescription =
    'Na T2 ' +
    aTumorType[tumorType].description +
    'zvýšení signálu stěny rekta (po směru hodinových ručiček) od ' +
    aTumorStart[tumorStart].text +
    ' po ' +
    aTumorEnd[tumorEnd].text +
    '. Infiltrace stěny rekta ' +
    ' začíná ' +
    (ARADistance > 0 ? ' přibližně ' + ARADistance + 'cm ' : '') +
    'od anorektálního úhlu a postihuje úsek délky cca ' +
    tumorLength +
    'cm, končí tedy přibližně ' +
    (ARADistance + tumorLength) +
    'cm orálně od anorektálního úhlu. ';

  /*let tumorGeneralConclusion =
    'Tumor ' +
    (ARADistance >= 0 && ARADistance < 5
      ? 'dolního '
      : ARADistance >= 5 && ARADistance < 10
      ? 'středního '
      : 'horního ') +
    'rekta přibližně ' +
    ARADistance +
    '-' +
    (ARADistance + tumorLength) +
    'cm od anorektálního úhlu - ';*/

  let tumorGeneralConclusion =
    'Tumor ' +
    (ARADistance >= 0 && ARADistance < 5
      ? 'dolního '
      : ARADistance >= 5 && ARADistance < 10
      ? 'středního '
      : 'horního ') +
    'rekta - ';

  // T stage
  let TStageDescription =
    Tstage === 0
      ? aTstage[Tstage].description
      : 'Tumor ' +
        aTumorStart[invasionStart].text +
        ' ' +
        aTumorEnd[invasionEnd].text +
        ' ' +
        aTstage[Tstage].description +
        (vascularInvasion ? ', s možnou  vaskulární invazí. ' : '. ') +
        aMRF[MRF].description;
  let TStageConclusion = aTstage[Tstage].conclusion + ' ';

  // N stage (regional lymph nodes)
  let NStageDescription = '';
  let iliacIntRsum = 0;
  let iliacIntLsum = 0;
  let obturatorRsum = 0;
  let obturatorLsum = 0;
  let mesorectalSum = 0;
  let upperRectalSum = 0;
  let cNstage = 'cN0';

  let aLNdetails = [];

  if (LNiliacIntR) {
    iliacIntRsum = LNiliacIntRCount + 1;
    aLNdetails.push(iliacIntRsum + 'x pravá vnitřní ilická LU');
  }
  if (LNiliacIntL) {
    iliacIntLsum = LNiliacIntLCount + 1;
    aLNdetails.push(iliacIntLsum + 'x levá vnitřní ilická LU');
  }
  if (LNobturatorR) {
    obturatorRsum = LNobturatorRCount + 1;
    aLNdetails.push(obturatorRsum + 'x pravá obturátorová LU');
  }
  if (LNobturatorL) {
    obturatorLsum = LNobturatorLCount + 1;
    aLNdetails.push(obturatorLsum + 'x levá obturátorová LU');
  }
  if (LNupperRectal) {
    upperRectalSum = LNupperRectalCount + 1;
    aLNdetails.push(upperRectalSum + 'x horní rektální LU');
  }
  if (LNmesorectal) {
    mesorectalSum = LNmesorectalCount + 1;
    aLNdetails.push(mesorectalSum + 'x mezorektální LU');
  }

  let regionalLNsum =
    iliacIntRsum +
    LNiliacIntL +
    obturatorRsum +
    obturatorLsum +
    mesorectalSum +
    upperRectalSum;

  if (regionalLNsum >= 7) {
    cNstage = 'cN2b';
  } else if (regionalLNsum >= 4) {
    cNstage = 'cN2a';
  } else if (regionalLNsum >= 2) {
    cNstage = 'cN1b';
  } else if (regionalLNsum === 1) {
    cNstage = 'cN1a';
  }

  importDescription1('cN stage:\t\t' + cNstage);
  if (aLNdetails.length) importDescription1('\t\t\t\t' + aLNdetails.join(', '));

  // cM stage - non-regionální lymfatické uzlin, metastázy
  importDescription1(
    '--------------------------------------------------------------------------------------------------------------------'
  );

  // M stage
  // non-regional lymph
  let paraaorticSum = 0;
  let iliacComRsum = 0;
  let iliacComLsum = 0;
  let iliacExtRsum = 0;
  let iliacExtLsum = 0;

  let aNonregionalLN = [];

  if (LNparaaortic) {
    paraaorticSum = LNparaaorticCount + 1;
    aNonregionalLN.push(paraaorticSum + 'x paraaortální LU');
  }
  if (LNiliacComR) {
    iliacComRsum = LNiliacComRCount + 1;
    aNonregionalLN.push(iliacComRsum + 'x pravá společná ilická LU');
  }
  if (LNiliacComL) {
    iliacComLsum = LNiliacComLCount + 1;
    aNonregionalLN.push(iliacComLsum + 'x levá společná ilická LU');
  }
  if (LNiliacExtR) {
    iliacExtRsum = LNiliacExtRCount + 1;
    aNonregionalLN.push(iliacExtRsum + 'x pravá zevní ilická LU');
  }
  if (LNiliacExtL) {
    iliacExtLsum = LNiliacExtLCount + 1;
    aNonregionalLN.push(iliacExtLsum + 'x levá zevní ilická LU');
  }

  let cMstage = 'cM0 ';
  if (aNonregionalLN.length > 0) cMstage = 'cM1a';

  importDescription1('cM stage:\t\t' + cMstage);
  if (aNonregionalLN.length)
    importDescription1('\t\t\t\t' + aNonregionalLN.join(', '));

  // MRF
  let MRFConclusion = MRF === 6 ? 'MRF+ ' : 'MRF- ';

  // EMVI
  let EMVIConclusion = 'EMVI- ';

  // ostatní nález

  importConclusion1(
    tumorGeneralConclusion +
      TStageConclusion +
      cNstage +
      ' ' +
      cMstage +
      ' ' +
      MRFConclusion +
      EMVIConclusion
  );

  //importConclusion1(mesorectalLNConclusion + LNConclusion);
  //importConclusion1(otherFindingsConclusion);

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
