import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aSpineSag,
  aSpineCor,
  aDiskPat,
  aAddFinds,
} from "./MR_spine_LS_arrays";
import {
  aListesis,
  aDiskDegen,
  aModic,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
  aFractureSite,
  aFractureEndplate,
  aFractureDepth,
  aFractureEdema,
  aFractureProminence,
  aSignal,
  aLaminectomy,
} from "./MR_spine_arrays";
import { generateReport } from "./MR_spine_LS_functions";

// General scripts
import {
  contentWidth,
  aCardColors,
  aTrueFalse,
  aSpine,
  aVertebrae,
  aSize,
} from "../scripts/GeneralArrays";
import { formatCustomInput, subtractArray } from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import DropDown from "../components/DropDown";
import ButtonUpDownRow from "../components/ButtonUpDownRow";

import {
  Form,
  Row,
  Col,
  Select,
  Tag,
  Space,
  Button,
  Input,
  Divider,
} from "antd";
const { Option } = Select;
const { CheckableTag } = Tag;
const { TextArea } = Input;

// Default
let firstSegment = 16;
let lastSegment = 23;
let aExamSegments = aSpine.filter(
  (item) => item.value >= firstSegment && item.value <= lastSegment
);

let aExamVertebrae = aVertebrae.filter(
  (item) => item.value >= firstSegment && item.value <= lastSegment
);

let aStabilisationRange = aVertebrae.filter(
  (item) => item.value >= firstSegment && item.value <= lastSegment + 1
);

class MR_spine_LS extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      firstSegment: firstSegment,
      lastSegment: lastSegment,
      spineCor: 0,
      spineSag: 0,
      hemangiomas: [],
      schmorls: [],
      spineCustomDescription: "",
      spineCustomConclusion: "",
      addFinds: [],
    },
    segments: aExamSegments.map((item) => {
      return {
        value: item.value,
        name: item.text,
        listesis: 0,
        listesisSize: 0,
        diskDegen: 0,
        Modic: 0,
        diskPat: 0,
        diskDirection: [],
        diskPatSize: 0,
        diskMigration: 0,
        diskMigrationSize: 0,
        facetArthrosis: 0,
        foramenR: 0,
        foramenL: 0,
        recessR: 0,
        recessL: 0,
        spinalCanal: 0,
      };
    }),
    compressions: [], //vertebra, endplate, area, compression, edema, prominence
    boneLesions: [],
    laminectomy: [],
    stabilisation: [],
    customVertebra: [],
    customSegment: [],
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value, segment) => {
    let newRecord;
    let array = [...this.state[segment]];
    console.log("n array: " + array.length);
    if (segment === "compressions") {
      newRecord = {
        vertebra: value,
        area: 0,
        compression: 0,
        endplate: 0,
        edema: 0,
        prominence: 0,
      };
      array.push(newRecord);
      console.log("p array: " + array.length);
      array = [...array].sort((a, b) => a.vertebra - b.vertebra);
      console.log("s array: " + array.length);
    } else if (segment === "boneLesions") {
      newRecord = {
        vertebra: value,
        T2: 3,
        T1: 3,
        STIR: 1,
        size: "",
        description: "",
        conclusion: "",
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebra - b.vertebra);
    } else if (segment === "laminectomy") {
      newRecord = {
        segment: value,
        side: 0,
        description: "",
        conclusion: "",
      };
      array.push(newRecord);
      array.sort((a, b) => a.segment - b.segment);
    } else if (segment === "stabilisation") {
      newRecord = {
        vertebraFirst: value,
        vertebraLast: value + 1,
        type: 0,
        description: "",
        conclusion: "",
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebraFirst - b.vertebraFirst);
    } else if (segment === "customSegment") {
      newRecord = {
        segment: value,
        description: "",
        conclusion: "",
      };
      array.push(newRecord);
      array.sort((a, b) => a.segment - b.segment);
    } else if (segment === "customVertebra") {
      newRecord = {
        vertebra: value,
        description: "",
        conclusion: "",
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebra - b.vertebra);
    }

    this.setState(() => ({
      [segment]: array,
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;

    this.setState({ [segment]: array });
  };

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  handleTags = (item, checked, index) => {
    let array = [...this.state.segments];
    let selectedTags = array[index].diskDirection;
    if (selectedTags.indexOf(item) === -1) {
      selectedTags.push(item);
    } else {
      selectedTags.splice(selectedTags.indexOf(item), 1);
    }
    selectedTags.sort(function (a, b) {
      return a.value - b.value;
    });
    array[index].diskDirection = selectedTags;
    this.setState({ segments: array });
  };

  render() {
    const tagWidth = "87px";
    const {
      compressions,
      boneLesions,
      laminectomy,
      stabilisation,
      customVertebra,
      customSegment,
    } = this.state;
    let showListesisSize =
      this.state.segments.filter((item) => item.listesis > 0).length > 0
        ? true
        : false;
    let showDiskPatSize =
      this.state.segments.filter(
        (item) => item.diskPat === 1 || item.diskPat === 2 || item.diskPat === 3
      ).length > 0
        ? true
        : false;

    let showDiskDirection =
      this.state.segments.filter(
        (item) =>
          (showDiskPatSize && item.diskPatSize > 0) || item.diskPat === 4
      ).length > 0
        ? true
        : false;

    let showDiskMigration =
      this.state.segments.filter(
        (item) =>
          item.diskPat === 1 &&
          item.diskPatSize > 0 &&
          item.diskDirection.length > 0
      ).length > 0
        ? true
        : false;

    let showDiskMigrationSize =
      this.state.segments.filter(
        (item) => showDiskMigration && item.diskMigration > 0
      ).length > 0
        ? true
        : false;

    const labelEmpty = 65;
    const labelListesis = 100;
    const labelListesisSize = 65;

    const labelDiskDegen = 77;
    const labelModic = 77;

    const labelDiskPat = 100;
    const labelDiskPatSize = 65;
    const labelDiskDirection = 113;
    const labelDiskMigration = 78;
    const labelDiskMigrationSize = 65;

    const labelFacetArthrosis = 61;

    const labelStenosis = 34;

    const spineSegments = [
      {
        title: "páteř - segmenty",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {
                    <Row>
                      <Col flex={labelEmpty + "px"}></Col>
                      <Col
                        flex={
                          showListesisSize
                            ? labelListesis + labelListesisSize + "px"
                            : labelListesis + "px"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          posun obratle
                        </Tag>
                      </Col>
                      <Col
                        flex={labelDiskDegen + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          degen. disk
                        </Tag>
                      </Col>
                      <Col
                        flex={labelModic + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          Modic
                        </Tag>
                      </Col>
                      <Col
                        flex={
                          labelDiskPat +
                          (showDiskPatSize ? labelDiskPatSize : 0) +
                          (showDiskDirection ? labelDiskDirection : 0) +
                          (showDiskMigration ? labelDiskMigration : 0) +
                          (showDiskMigrationSize ? labelDiskMigrationSize : 0) +
                          "px"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          segment pat.
                        </Tag>
                      </Col>
                      <Col
                        flex={labelFacetArthrosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          FA
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PF
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PR
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PK
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          LR
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          LF
                        </Tag>
                      </Col>
                    </Row>
                  }
                  {this.state.segments.map((item, index) => {
                    return (
                      <Row style={{ marginBottom: "1px" }}>
                        <Col
                          flex={labelEmpty + "px"}
                          style={{ textAlign: "right", paddingRight: "5px" }}
                        >
                          <Tag
                            style={{
                              width: "100%",
                              margin: 0,
                              backgroundColor: aCardColors.blue.header,
                            }}
                          >
                            {item.name}
                          </Tag>
                        </Col>
                        <Col flex={labelListesis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="listesis"
                            segment="segments"
                            value={item.listesis}
                            options={aListesis}
                            size="small"
                            style={{ width: labelListesis + "px" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        {showListesisSize ? (
                          <Col flex={labelListesisSize + "px"}>
                            {item.listesis > 0 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="listesisSize"
                                segment="segments"
                                value={item.listesisSize}
                                options={aSize}
                                updateRow={this.updateRow}
                                isDisabled={item.listesis === 0 ? true : false}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col flex={labelDiskDegen + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="diskDegen"
                            segment="segments"
                            value={item.diskDegen}
                            options={aDiskDegen}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelModic + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="Modic"
                            segment="segments"
                            value={item.Modic}
                            options={aModic}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelDiskPat + "px"}>
                          <Select
                            key={index}
                            value={item.diskPat}
                            defaultValue={item.diskPat}
                            size="small"
                            showArrow={false}
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "segments",
                                "diskPat",
                                value
                              )
                            }
                          >
                            {aDiskPat.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        {showDiskPatSize ? (
                          <Col flex={labelDiskPatSize + "px"}>
                            {item.diskPat !== 0 && item.diskPat !== 4 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="diskPatSize"
                                segment="segments"
                                value={item.diskPatSize}
                                options={aSize}
                                style={{ width: "100%" }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskPat === 0 || item.diskPat === 4
                                    ? true
                                    : false
                                }
                              />
                            ) : null}
                          </Col>
                        ) : null}
                        {showDiskDirection ? (
                          <Col flex={labelDiskDirection + "px"}>
                            <Row>
                              {item.diskPat === 0
                                ? null
                                : aDiskDirection.map((item) => (
                                    <Col>
                                      <CheckableTag
                                        key={item.value}
                                        checked={
                                          this.state.segments[
                                            index
                                          ].diskDirection.indexOf(item) > -1
                                        }
                                        onChange={(checked) =>
                                          this.handleTags(item, checked, index)
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        {item.text}
                                      </CheckableTag>
                                    </Col>
                                  ))}
                            </Row>
                          </Col>
                        ) : (
                          ""
                        )}
                        {showDiskMigration ? (
                          <Col flex={labelDiskMigration + "px"}>
                            {item.diskPat === 1 &&
                            item.diskDirection.length > 0 ? (
                              <Select
                                key={index}
                                value={item.diskMigration}
                                defaultValue={item.diskMigration}
                                size="small"
                                showArrow={false}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  this.updateRow(
                                    index,
                                    "segments",
                                    "diskMigration",
                                    value
                                  )
                                }
                              >
                                {aDiskMigration.map((item) => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ) : null}
                          </Col>
                        ) : (
                          ""
                        )}
                        {showDiskMigrationSize ? (
                          <Col flex={labelDiskMigrationSize + "px"}>
                            {item.diskMigration > 0 && item.diskPat > 0 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="diskMigrationSize"
                                segment="segments"
                                value={item.diskMigrationSize}
                                options={aSize}
                                style={{ width: "100%" }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskMigration > 0 ? false : true
                                }
                              />
                            ) : null}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col flex={labelFacetArthrosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="facetArthrosis"
                            segment="segments"
                            value={item.facetArthrosis}
                            options={aTrueFalse}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="foramenR"
                            segment="segments"
                            value={item.foramenR}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="recessR"
                            segment="segments"
                            value={item.recessR}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="spinalCanal"
                            segment="segments"
                            value={item.spinalCanal}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="recessL"
                            segment="segments"
                            value={item.recessL}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="foramenL"
                            segment="segments"
                            value={item.foramenL}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    const generalSpine = [
      {
        title: "páteř - obecně",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "LS páteř",
              input: (
                <DropDown
                  name="spineCor"
                  segment="general"
                  defaultValue={this.state.general.spineCor}
                  options={aSpineCor}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "lordóza",
              input: (
                <DropDown
                  name="spineSag"
                  segment="general"
                  defaultValue={this.state.general.spineSag}
                  options={aSpineSag}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "hemangiomy",
              input: (
                <DropDown
                  name="hemangiomas"
                  segment="general"
                  defaultValue={this.state.general.hemangiomas}
                  options={aExamVertebrae}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "Schmorl. uzly",
              input: (
                <DropDown
                  name="schmorls"
                  segment="general"
                  defaultValue={this.state.general.schmorls}
                  options={aExamSegments}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "vedlejší nálezy",
              input: (
                <DropDown
                  name="addFinds"
                  segment="general"
                  defaultValue={this.state.general.addFinds}
                  options={aAddFinds}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "páteř - vlastní záznam",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "popis",
              input: (
                <TextArea
                  size="small"
                  name="spineCustomDescription"
                  segment="general"
                  defaultValue={this.state.general.spineCustomDescription}
                  placeholder="..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "spineCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "závěr",
              input: (
                <TextArea
                  size="small"
                  name="spineCustomConclusion"
                  segment="general"
                  defaultValue={this.state.general.spineCustomConclusion}
                  placeholder="..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "spineCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    const customEntry = [
      {
        title: (
          <Space>
            <Select
              value="komprese obratle"
              size="small"
              onChange={(value) => {
                this.newRow(value, "compressions");
              }}
            >
              {subtractArray(aExamVertebrae, compressions).map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value="ložisko obratle"
              size="small"
              onChange={(value) => {
                this.newRow(value, "boneLesions");
              }}
            >
              {subtractArray(aExamVertebrae, boneLesions).map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value="stabilizace"
              size="small"
              onChange={(value) => {
                this.newRow(value, "stabilisation");
              }}
            >
              {subtractArray(aStabilisationRange, stabilisation).map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value="laminektomie"
              size="small"
              onChange={(value) => {
                this.newRow(value, "laminectomy");
              }}
            >
              {subtractArray(aExamSegments, laminectomy).map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value="vlastní - segment"
              size="small"
              onChange={(value) => {
                this.newRow(value, "customSegment");
              }}
            >
              {subtractArray(aExamSegments, customSegment).map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value="vlastní - obratel"
              size="small"
              onChange={(value) => {
                this.newRow(value, "customVertebra");
              }}
            >
              {subtractArray(aExamVertebrae, customVertebra).map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
          </Space>
        ),
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {this.state.compressions.map((item, index) => {
                    console.log("item: " + item.vertebra + " index: " + index);
                    return (
                      <Row>
                        <Col
                          span={23}
                          style={{ marginBottom: "10px" }}
                          key={index}
                        >
                          <Space size="0">
                            <Tag color="#ff4d4f" style={{ width: tagWidth }}>
                              komprese
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebra}
                              defaultValue={item.vertebra}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "compressions",
                                  "vertebra",
                                  value
                                )
                              }
                            >
                              {aExamVertebrae.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type="vertical" />
                            <ButtonUpDownRow
                              index={index}
                              name="area"
                              segment="compressions"
                              value={item.area}
                              defaultValue={item.area}
                              options={aFractureSite}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            <ButtonUpDownRow
                              index={index}
                              name="compression"
                              segment="compressions"
                              value={item.compression}
                              defaultValue={item.compression}
                              options={aFractureDepth}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            <ButtonUpDownRow
                              index={index}
                              name="endplate"
                              defaultValue={item.endplate}
                              segment="compressions"
                              value={item.endplate}
                              options={aFractureEndplate}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            <ButtonUpDownRow
                              index={index}
                              name="edema"
                              segment="compressions"
                              value={item.edema}
                              defaultValue={item.edema}
                              options={aFractureEdema}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            <ButtonUpDownRow
                              index={index}
                              name="prominence"
                              segment="compressions"
                              value={item.prominence}
                              defaultValue={item.prominence}
                              options={aFractureProminence}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                          </Space>
                        </Col>
                        <Col span={1} align="right">
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() =>
                              this.deleteRow(index, "compressions")
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.boneLesions.map((item, index) => {
                    return (
                      <Row>
                        <Col
                          flex="auto"
                          style={{ marginBottom: "10px" }}
                          key={index}
                        >
                          <Space size="0">
                            <Tag color="#ffa940" style={{ width: tagWidth }}>
                              ložisko
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebra}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "boneLesions",
                                  "vertebra",
                                  value
                                )
                              }
                            >
                              {aExamVertebrae.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type="vertical" />
                            T2
                            <ButtonUpDownRow
                              index={index}
                              name="T2"
                              segment="boneLesions"
                              value={item.T2}
                              options={aSignal}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            T1
                            <ButtonUpDownRow
                              index={index}
                              name="T1"
                              segment="boneLesions"
                              value={item.T1}
                              options={aSignal}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            STIR
                            <ButtonUpDownRow
                              index={index}
                              name="STIR"
                              segment="boneLesions"
                              value={item.STIR}
                              options={aSignal}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                            <Divider type="vertical" />
                            <Input
                              name="size"
                              value={item.size}
                              size="small"
                              placeholder="velikost..."
                              suffix="mm"
                              onChange={(event) =>
                                this.updateRow(
                                  index,
                                  "boneLesions",
                                  "size",
                                  event.target.value
                                )
                              }
                              style={{ width: "100px" }}
                            />
                          </Space>
                        </Col>
                        <Col flex="auto" align="right">
                          <Space size="0">
                            <TextArea
                              defaultValue={item.description}
                              size="small"
                              placeholder="doplňující popis ložiska..."
                              style={{ width: "300px" }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "boneLesions",
                                  "description",
                                  event.target.value
                                )
                              }
                            />
                            <Divider type="vertical" />
                            <TextArea
                              defaultValue={item.conclusion}
                              size="small"
                              placeholder="doplňující závěr ložiska..."
                              style={{ width: "300px" }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "boneLesions",
                                  "conclusion",
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col
                          flex="35px"
                          align="right"
                          style={{ margin: "auto" }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() => this.deleteRow(index, "boneLesions")}
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.stabilisation.map((item, index) => {
                    return (
                      <Row>
                        <Col flex="auto" key={index}>
                          <Space size="0">
                            <Tag color="#096dd9" style={{ width: tagWidth }}>
                              stabilizace
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebraFirst}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "stabilisation",
                                  "vertebraFirst",
                                  value
                                )
                              }
                            >
                              {aStabilisationRange.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type="vertical" />
                            <Select
                              key={index}
                              value={item.vertebraLast}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "stabilisation",
                                  "vertebraLast",
                                  value
                                )
                              }
                            >
                              {aStabilisationRange
                                .filter(
                                  (vertebra) =>
                                    vertebra.value >= item.vertebraFirst
                                )
                                .map((item) => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Space>
                        </Col>
                        <Col flex="auto" align="right">
                          <Space size="0">
                            <TextArea
                              defaultValue={item.description}
                              size="small"
                              placeholder="doplňující popis stabilizace..."
                              style={{ width: "300px" }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "stabilisation",
                                  "description",
                                  event.target.value
                                )
                              }
                            />
                            <Divider type="vertical" />
                            <TextArea
                              defaultValue={item.conclusion}
                              size="small"
                              placeholder="doplňující závěr stabilizace..."
                              style={{ width: "300px" }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "stabilisation",
                                  "conclusion",
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col
                          flex="35px"
                          align="right"
                          style={{ margin: "auto" }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() =>
                              this.deleteRow(index, "stabilisation")
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  {this.state.laminectomy.map((item, index) => {
                    return (
                      <Row>
                        <Col flex="auto" key={index}>
                          <Space size="0">
                            <Tag color="#2db7f5" style={{ width: tagWidth }}>
                              laminektomie
                            </Tag>
                            <Select
                              key={index}
                              value={item.segment}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "laminectomy",
                                  "segment",
                                  value
                                )
                              }
                            >
                              {aExamSegments.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type="vertical" />
                            <ButtonUpDownRow
                              index={index}
                              name="side"
                              segment="laminectomy"
                              value={item.side}
                              options={aLaminectomy}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                          </Space>
                        </Col>
                        <Col flex="auto" align="right">
                          <Space size="0px">
                            <TextArea
                              defaultValue={item.description}
                              size="small"
                              placeholder="doplňující popis laminektomie..."
                              style={{ width: "300px" }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "laminectomy",
                                  "description",
                                  event.target.value
                                )
                              }
                            />
                            <Divider type="vertical" />
                            <TextArea
                              defaultValue={item.conclusion}
                              size="small"
                              placeholder="doplňující závěr laminektomie..."
                              style={{ width: "300px" }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "laminectomy",
                                  "conclusion",
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col
                          flex="35px"
                          align="right"
                          style={{ margin: "auto" }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() => this.deleteRow(index, "laminectomy")}
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.customSegment.map((item, index) => {
                    return (
                      <Row>
                        <Col flex="auto" key={index}>
                          <Space size="0">
                            <Tag color="#2db7f5" style={{ width: tagWidth }}>
                              segment
                            </Tag>
                            <Select
                              key={index}
                              value={item.segment}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "customSegment",
                                  "segment",
                                  value
                                )
                              }
                            >
                              {aExamSegments.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type="vertical" />
                          </Space>
                        </Col>
                        <Col flex="auto" align="right">
                          <Space size="0px">
                            <TextArea
                              defaultValue={item.description}
                              size="small"
                              placeholder="popis..."
                              style={{ width: "475px" }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "customSegment",
                                  "description",
                                  event.target.value
                                )
                              }
                            />
                            <Divider type="vertical" />
                            <TextArea
                              defaultValue={item.conclusion}
                              size="small"
                              placeholder="závěr..."
                              style={{ width: "475px" }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "customSegment",
                                  "conclusion",
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col
                          flex="35px"
                          align="right"
                          style={{ margin: "auto" }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() =>
                              this.deleteRow(index, "customSegment")
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.customVertebra.map((item, index) => {
                    return (
                      <Row>
                        <Col flex="auto" key={index}>
                          <Space size="0">
                            <Tag color="#2db7f5" style={{ width: tagWidth }}>
                              obratel
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebra}
                              size="small"
                              style={{ width: "90px" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "customVertebra",
                                  "vertebra",
                                  value
                                )
                              }
                            >
                              {aExamVertebrae.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type="vertical" />
                          </Space>
                        </Col>
                        <Col flex="auto" align="right">
                          <Space size="0px">
                            <TextArea
                              defaultValue={item.description}
                              size="small"
                              placeholder="popis..."
                              style={{ width: "475px" }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "customVertebra",
                                  "description",
                                  event.target.value
                                )
                              }
                            />
                            <Divider type="vertical" />
                            <TextArea
                              defaultValue={item.conclusion}
                              size="small"
                              placeholder="závěr..."
                              style={{ width: "475px" }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={(event) =>
                                this.updateRow(
                                  index,
                                  "customVertebra",
                                  "conclusion",
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col
                          flex="35px"
                          align="right"
                          style={{ margin: "auto" }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() =>
                              this.deleteRow(index, "customVertebra")
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={spineSegments} />
        <CustomCards content={customEntry} />
        <CustomCards content={generalSpine} />
      </Form>
    );
  }
}

export default MR_spine_LS;
