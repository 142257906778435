// Custom scripts
import {
  aExams,
  aHyperplasia,
  aSeminalVesicles,
  aSegments,
  aLevel,
  aSite,
  aT2,
  aDWI,
  aDCE,
  aExtraprostatic,
  aScore,
  aLNcount,
} from "../MR/MR_prostate_arrays";

// General scripts
import { indent, br } from "../scripts/GeneralArrays";
import {
  formatArrayToText,
  capitelizeFirstLetter,
} from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Data
  const {
    size,
    hyperplasia,
    seminalVesicles,
    kidneysDescription,
    kidneysConclusion,
    bladderDescription,
    bladderConclusion,
    rectosigmaDescription,
    rectosigmaConclusion,
    bonesDescription,
    bonesConclusion,
    ascitesDescription,
    ascitesConclusion,
    LNparaaortic,
    LNiliacComR,
    LNiliacComL,
    LNiliacExtR,
    LNiliacExtL,
    LNiliacIntR,
    LNiliacIntL,
    LNinguinalR,
    LNinguinalL,
    LNparaaorticCount,
    LNiliacComRCount,
    LNiliacComLCount,
    LNiliacExtRCount,
    LNiliacExtLCount,
    LNiliacIntRCount,
    LNiliacIntLCount,
    LNinguinalRCount,
    LNinguinalLCount,
    LNcustomDescription,
    LNcustomConclusion,
  } = props.general;
  const { lesions } = props;

  const isContrast = props.report.contrast > 0 ? true : false;

  // Ložiska

  let lesionDescription = "";
  let lesionConclusion = "";

  console.log(protocol);

  if (lesions.length > 0) {
    lesions.map((item, index) => {
      console.log("PIRADS: " + item.area[0]);
      lesionDescription +=
        "Ložisko č." +
        (index + 1) +
        " - " +
        aSite[item.site].description +
        aLevel[item.level].description +
        "v segmentu " +
        formatArrayToText(item.area.map((item) => aSegments[item].text)) +
        " " +
        (item.area[0] < 3
          ? aT2[item.T2].descriptionPZ
          : aT2[item.T2].descriptionTZ) +
        ", " +
        aDWI[item.DWI].description +
        ", " +
        //aDCE[item.DCE].description +
        (item.lesionSize !== ""
          ? ", velikosti " + item.lesionSize + "mm "
          : "") +
        (item.slice !== "" ? "(série/řez: " + item.slice + ")" : "");

      lesionConclusion +=
        "Ložisko č." +
        (index + 1) +
        " - " +
        aScore[item.score].conclusion +
        ", " +
        aLevel[item.level].conclusion +
        " " +
        aSite[item.site].text +
        ", " +
        " segment " +
        formatArrayToText(item.area.map((item) => aSegments[item].text)) +
        (item.lesionSize !== ""
          ? ", velikost: " + item.lesionSize + "mm, "
          : "") +
        (item.slice !== "" ? "(série/řez: " + item.slice + ")" : "");
      importDescription1(lesionDescription);
      importConclusion1(lesionConclusion);
      lesionDescription = lesionConclusion = "";
    });
  } else {
    lesionConclusion =
      "Bez přesvědčivých okrsků nádorové infiltrace prostaty. ";
    importConclusion1(lesionConclusion);
  }

  // Lymfatické uzliny
  let LNDescription = "";
  let LNConclusion = "";
  let LNisPat = false;
  let aEnlargedLN = [];
  let aEnlargedLNcount = [];
  let aBorderlineLN = [];
  let aBorderlineLNcount = [];

  if (LNparaaortic > 1) {
    aEnlargedLNcount.push(LNparaaorticCount);
    aEnlargedLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  } else if (LNparaaortic === 1) {
    aBorderlineLNcount.push(LNparaaorticCount);
    aBorderlineLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  }
  if (LNiliacComR > 1) {
    aEnlargedLNcount.push(LNiliacComRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  } else if (LNiliacComR === 1) {
    aBorderlineLNcount.push(LNiliacComRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  }
  if (LNiliacComL > 1) {
    aEnlargedLNcount.push(LNiliacComLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  } else if (LNiliacComL === 1) {
    aBorderlineLNcount.push(LNiliacComLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  }
  if (LNiliacExtR > 1) {
    aEnlargedLNcount.push(LNiliacExtRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  } else if (LNiliacExtR === 1) {
    aBorderlineLNcount.push(LNiliacExtRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  }
  if (LNiliacExtL > 1) {
    aEnlargedLNcount.push(LNiliacExtLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  } else if (LNiliacExtL === 1) {
    aBorderlineLNcount.push(LNiliacExtLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  }
  if (LNiliacIntR > 1) {
    aEnlargedLNcount.push(LNiliacIntRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  } else if (LNiliacIntR === 1) {
    aBorderlineLNcount.push(LNiliacIntRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  }
  if (LNiliacIntL > 1) {
    aEnlargedLNcount.push(LNiliacIntLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  } else if (LNiliacIntL === 1) {
    aBorderlineLNcount.push(LNiliacIntLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  }
  if (LNinguinalR > 1) {
    aEnlargedLNcount.push(LNinguinalRCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? "pravostranné inguinální"
          : "pravostranná inguinální")
    );
  } else if (LNinguinalR === 1) {
    aBorderlineLNcount.push(LNinguinalRCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? "pravostranné inguinální"
          : "pravostranná inguinální")
    );
  }
  if (LNinguinalL > 1) {
    aEnlargedLNcount.push(LNinguinalLCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? "levostranné inguinální"
          : "levostranná inguinální")
    );
  } else if (LNinguinalL === 1) {
    aBorderlineLNcount.push(LNinguinalLCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? "levostranné inguinální"
          : "levostranná inguinální")
    );
  }

  if (aEnlargedLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aEnlargedLN) +
        (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose větší než 10mm. "
    );
    LNConclusion +=
      (aEnlargedLNcount[0] > 0 ? "Zvětšené " : "Zvětšená ") +
      formatArrayToText(aEnlargedLN) +
      (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (aBorderlineLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aBorderlineLN) +
        (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose přibližně 10mm. "
    );
    LNConclusion +=
      "Velikostí hraniční " +
      formatArrayToText(aBorderlineLN) +
      (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (
    aEnlargedLN.length > 0 ||
    aBorderlineLN.length > 0 ||
    LNcustomDescription !== ""
  ) {
    LNDescription +=
      LNcustomDescription +
      "Jinak v zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  } else {
    LNDescription +=
      "V zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  }

  if (LNcustomConclusion !== "") {
    LNConclusion += LNcustomConclusion;
  }

  // Prostata obecně
  let prostateGeneralDescription = "";
  prostateGeneralDescription +=
    (size !== ""
      ? "Prostata velikosti " + size + "mm. "
      : "Prostata normální velikosti. ") +
    aHyperplasia[hyperplasia].description +
    aSeminalVesicles[seminalVesicles].description +
    (props.lesions.length === 0
      ? "V segmentech prostaty bez ložisek dle PI-RADS score vyššího než 2. "
      : "") +
    (kidneysDescription === ""
      ? "Normální nález na ledvinách, bez dilatace dutého systému. "
      : kidneysDescription +
        "Jinak normální nález na ledvinách, bez dilatace dutého systému. ") +
    (bladderDescription === ""
      ? "Bez průkazu přesvědčivé nádorové infiltrace stěny močového měchýře. "
      : bladderDescription + "Jinak přiměřený nález na močovém měchýři. ") +
    (rectosigmaDescription === ""
      ? "Bez průkazu přesvědčivé nádorové infiltrace stěny rektosigmatu. "
      : rectosigmaDescription + "Jinak přiměřený nález na rektosigmatu. ") +
    (ascitesDescription === ""
      ? "Bez volné tekutiny intraabdominálně. "
      : ascitesDescription) +
    (bonesDescription === ""
      ? "V zobrazeném rozsahu bez přesvědčivých ložisek v kostní dřeni obrazu metastáz. "
      : bonesConclusion + "Jinak přiměřený nález na zobrazeném skeletu. ");

  let prostateGeneralConclusion = "";
  prostateGeneralConclusion =
    (lesions.length > 0 && LNConclusion === ""
      ? "Bez průkazu pánevní lymfadenopatie. "
      : LNConclusion) +
    bonesConclusion +
    ascitesConclusion +
    bladderConclusion +
    kidneysConclusion +
    rectosigmaConclusion +
    aHyperplasia[hyperplasia].conclusion;

  importDescription1(prostateGeneralDescription);
  importConclusion1(prostateGeneralConclusion);

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
