import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Row, Col, Input, Button, Tabs, Select, Dropdown, Menu } from 'antd';

// Custom scripts

// General scripts
import {
  aContrastMR,
  aContrastMRamount,
  colorMRI,
} from '../scripts/GeneralArrays';
import { removeAllNewLines } from '../scripts/GeneralFunctions';

// Custom components
import DropDown from '../components/DropDown';
import Report_new from '../components/Report_new';
import { PlusOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form/Form';

import MR_brain from './MR_brain';
import MR_spine_C from './MR_spine_C';
import MR_spine_Th from './MR_spine_Th';
import MR_spine_LS from './MR_spine_LS';
import MR_knee from './MR_knee';
import MR_shoulder from './MR_shoulder';
import MR_wrist from './MR_wrist';
import MR_elbow from './MR_elbow';
import MR_hip from './MR_hip';
import MR_liver from './MR_liver';
import MR_prostate from './MR_prostate';
import MR_rectum from './MR_rectum';
import MR_cervix from './MR_cervix';
import MR_pelvis from './MR_pelvis';
import MR_enterography from './MR_enterography';
import MR_abdomen from './MR_abdomen';
import MR_neck from './MR_neck';
import MR_ankle from './MR_ankle';
import MR_SIJ from './MR_SIJ';

// 3rd party components
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const Option = Select.Option;

const initialPanes = [];
let examIndex = 0;

const aMRExams = [
  {
    value: examIndex++,
    Group: 'neuro',
    Selector: 'MR mozku',
    TabName: 'MR mozku ',
    TagName: MR_brain,
    protocols: [
      {
        value: 0,
        tab: 'protokol základní',
        report: 'mozku',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'protokol zadní jáma',
        report: 'mozku (protokol zadní jáma)',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 2,
        tab: 'protokol volumetrie',
        report: 'mozku (protokol volumetrie)',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 3,
        tab: 'protokol hypofýza',
        report: 'mozku (protokol hypofýza)',
        contrast: 1,
        contrastAmount: 5,
      },
      {
        value: 4,
        tab: 'protokol vaskulitida',
        report: 'mozku (protokol vaskulitida)',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 5,
        tab: 'a C míchy, protokol RS',
        report: 'mozku a C míchy (protokol RS)',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 6,
        tab: 'a mozkových tepen',
        report: 'mozku a MRA mozkových tepen',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 7,
        tab: 'a žilních splavů',
        report: 'mozku a MRV žilních splavů',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 8,
        tab: 'protokol epilepsie',
        report: 'mozku (protokol epilepsie)',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'neuro',
    Selector: 'MR C páteře',
    TabName: 'MR ',
    TagName: MR_spine_C,
    protocols: [
      {
        value: 0,
        tab: 'C páteře',
        report: 'C páteře',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'C páteře a C/Th přechodu',
        report: 'C páteře a C/Th přechodu',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'neuro',
    Selector: 'MR Th páteře',
    TabName: 'MR ',
    TagName: MR_spine_Th,
    protocols: [
      {
        value: 0,
        tab: 'Th páteře',
        report: 'Th páteře',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'Th páteře a C/Th přechodu',
        report: 'Th páteře a C/Th přechodu',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'neuro',
    Selector: 'MR LS páteře',
    TabName: 'MR ',
    TagName: MR_spine_LS,
    protocols: [
      {
        value: 0,
        tab: 'LS páteře',
        report: 'LS páteře',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'LS páteře a Th/L přechodu',
        report: 'LS páteře a Th/L přechodu',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'neuro',
    Selector: 'MR krku',
    TabName: 'MR krku',
    TagName: MR_neck,
    protocols: [
      {
        value: 0,
        tab: '',
        report: 'krku',
        contrast: 1,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR ramene',
    TabName: 'MR ',
    TagName: MR_shoulder,
    protocols: [
      {
        value: 0,
        tab: 'pravého ramene',
        report: 'pravého ramenního kloubu',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'levého ramene',
        report: 'levého ramenního kloubu',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR lokte',
    TabName: 'MR ',
    TagName: MR_elbow,
    protocols: [
      {
        value: 0,
        tab: 'pravého lokte',
        report: 'pravého lokte',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'levého lokte',
        report: 'levého lokte',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR zápěstí',
    TabName: 'MR ',
    TagName: MR_wrist,
    protocols: [
      {
        value: 0,
        tab: 'pravého zápěstí',
        report: 'pravého zápěstí',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'levého zápěstí',
        report: 'levého zápěstí',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR kyčle',
    TabName: 'MR ',
    TagName: MR_hip,
    protocols: [
      {
        value: 0,
        tab: 'pravé kyčle',
        report: 'pravého kyčelního kloubu',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'levé kyčle',
        report: 'levého kyčelního kloubu',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 2,
        tab: 'obou kyčlí',
        report: 'obou kyčelních kloubů',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR kolene',
    TabName: 'MR ',
    TagName: MR_knee,
    protocols: [
      {
        value: 0,
        tab: 'pravého kolene',
        report: 'pravého kolenního kloubu',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'levého kolene',
        report: 'levého kolenního kloubu',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR hlezna',
    TabName: 'MR ',
    TagName: MR_ankle,
    protocols: [
      {
        value: 0,
        tab: 'pravého hlezna',
        report: 'pravého hlezna',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'levého hlezna',
        report: 'levého hlezna',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'MSK',
    Selector: 'MR SI kloubů',
    TabName: 'MR SI kloubů',
    TagName: MR_SIJ,
    protocols: [
      {
        value: 0,
        tab: 'SI kloubů',
        report: 'SI kloubů',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  // břicho a pánev
  {
    value: examIndex++,
    Group: 'břicho a pánev',
    Selector: 'MR jater',
    TabName: 'MR ',
    TagName: MR_liver,
    protocols: [
      {
        value: 0,
        tab: 'jater',
        report: 'jater',
        contrast: 4,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'CP',
        report: 'cholangiopankreatikografie',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'břicho a pánev',
    Selector: 'MR pánve',
    TabName: 'MR pánve - ',
    TagName: MR_pelvis,
    protocols: [
      {
        value: 0,
        tab: 'muž',
        report: 'pánve',
        contrast: 0,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'žena',
        report: 'pánve',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'břicho a pánev',
    Selector: 'MR břicha',
    TabName: 'MR břicha - ',
    TagName: MR_abdomen,
    protocols: [
      {
        value: 0,
        tab: 'celé břicho',
        report: 'břicha',
        contrast: 1,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: 'ledviny',
        report: 'ledvin',
        contrast: 1,
        contrastAmount: 5,
      },
      {
        value: 2,
        tab: 'pankreas',
        report: 'pankreatu',
        contrast: 1,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'břicho a pánev',
    Selector: 'MR enterografie',
    TabName: 'MR enterografie ',
    TagName: MR_enterography,
    protocols: [
      {
        value: 0,
        tab: '- m. Crohn (muž)',
        report: 'enterografie',
        contrast: 1,
        contrastAmount: 5,
      },
      {
        value: 1,
        tab: '- m. Crohn (žena)',
        report: 'enterografie',
        contrast: 1,
        contrastAmount: 5,
      },
      {
        value: 2,
        tab: '- obecný protokol (muž)',
        report: 'enterografie',
        contrast: 1,
        contrastAmount: 5,
      },
      {
        value: 3,
        tab: '- obecný protokol (žena)',
        report: 'enterografie',
        contrast: 1,
        contrastAmount: 5,
      },
    ],
  },
  // staging
  {
    value: examIndex++,
    Group: 'staging',
    Selector: 'MR ca prostaty',
    TabName: 'MR ca prostaty',
    TagName: MR_prostate,
    protocols: [
      {
        value: 0,
        tab: '',
        report: 'prostaty',
        contrast: 1,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'staging',
    Selector: 'MR ca rekta',
    TabName: 'MR ca rekta',
    TagName: MR_rectum,
    protocols: [
      {
        value: 0,
        tab: '',
        report: 'pánve - staging tumoru rekta',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
  {
    value: examIndex++,
    Group: 'staging',
    Selector: 'MR ca děl. čípku',
    TabName: 'MR ca děl. čípku',
    TagName: MR_cervix,
    protocols: [
      {
        value: 0,
        tab: 'MR dělohy',
        report: 'pánve - staging tumoru děložního čípku',
        contrast: 0,
        contrastAmount: 5,
      },
    ],
  },
];

const defaultReport = {
  exam: [],
  examList: aMRExams,
  indication: '',
  contrast: 0,
  contrastAmount: 5,
  contrastArray: aContrastMR,
  contrastAmountArray: aContrastMRamount,
};

class ExamHolder_MR extends React.Component {
  newTabIndex = 0;
  state = {
    key: 0,
    contrast: 0,
    contrastAmount: 5,
    indication: '',
    report: defaultReport,
    reports: [],
    panes: initialPanes,
    activeKey: 0,
    reset: false,
  };

  examRef = React.createRef();

  resetExam = () => {
    this.setState({ reset: true });
  };

  resetReply = () => {
    var key = this.state.key + 1;
    this.setState({ key: key, report: defaultReport, reset: false });
  };

  renderComponent = (TagName, TagFunction, examID, paneIndex) => {
    return (
      <TagName
        askReport={TagFunction}
        exam={examID}
        protocol={this.state.panes[paneIndex].protocol}
        contrast={this.state.report.contrast}
        ref={this.examRef}
        reset={this.state.reset}
        resetReply={this.resetReply}
      />
    );
  };

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;
    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  onChangeTabs = activeKey => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  remove = targetKey => {
    const { panes, activeKey } = this.state;
    let newActiveKey = activeKey;
    let lastIndex;
    panes.forEach((pane, index) => {
      if (pane.key === targetKey) {
        lastIndex = index - 1;
      }
    });
    const newPanes = panes.filter(pane => pane.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    this.setState({
      panes: newPanes,
      activeKey: newActiveKey,
      report: panes.length === 1 ? defaultReport : this.state.report,
    });
  };

  getReport = result => {
    let { panes } = this.state;
    const {
      exam,
      description_1_for_UI,
      description_2_for_UI,
      description_1_for_Paste,
      description_2_for_Paste,
      conclusion_1_for_UI,
      conclusion_1_for_Paste,
      conclusion_2_for_UI,
      conclusion_2_for_Paste,
    } = result;
    for (let i = 0; i < panes.length; i++) {
      if (panes[i].value === exam) {
        panes[i].description_1_for_UI = description_1_for_UI;
        panes[i].description_1_for_Paste = description_1_for_Paste;
        panes[i].description_2_for_UI = description_2_for_UI;
        panes[i].description_2_for_Paste = description_2_for_Paste;
        panes[i].conclusion_1_for_UI = conclusion_1_for_UI;
        panes[i].conclusion_1_for_Paste = conclusion_1_for_Paste;
        panes[i].conclusion_2_for_UI = conclusion_2_for_UI;
        panes[i].conclusion_2_for_Paste = conclusion_2_for_Paste;
        this.setState({ panes: panes });
        break;
      }
    }
  };

  add = event => {
    const selectedKey = event.key;
    const { panes } = this.state;
    const activeKey = `${this.newTabIndex++}`;
    const newPanes = [...panes];
    newPanes.push({
      key: activeKey,
      value: aMRExams[selectedKey].value,
      TabName: aMRExams[selectedKey].TabName,
      protocol: 0,
      protocols: aMRExams[selectedKey].protocols,
      TagName: aMRExams[selectedKey].TagName,
      description_1_for_UI: null,
      description_2_for_UI: null,
      description_1_for_Paste: null,
      description_2_for_Paste: null,
      conclusion_for_UI: null,
      conclusion_for_Paste: null,
    });

    newPanes.sort((a, b) => (a.value > b.value ? 1 : -1));

    this.setState({
      panes: newPanes,
      activeKey,
    });
  };

  updateProtocol = ({ pane, protocol }) => {
    var newState = { ...this.state };
    var { panes, report } = this.state;
    var updatedContrast =
      aMRExams[panes[pane].value].protocols[protocol].contrast;
    var updatedContrastAmount =
      aMRExams[panes[pane].value].protocols[protocol].contrastAmount;
    panes[pane].protocol = protocol;
    report.contrast = updatedContrast;
    report.contrastAmount = updatedContrastAmount;
    newState.panes = panes;
    newState.report = report;
    console.log(newState);
    this.setState({ newState });
  };

  render() {
    const { panes, activeKey } = this.state;

    let examGroups = [];
    examGroups.neuro = aMRExams.filter(item => item.Group === 'neuro');
    examGroups.MSK = aMRExams.filter(item => item.Group === 'MSK');
    examGroups.abdominopelvic = aMRExams.filter(
      item => item.Group === 'břicho a pánev'
    );
    examGroups.staging = aMRExams.filter(item => item.Group === 'staging');

    let menu = (
      <Menu onClick={this.add}>
        <Menu.ItemGroup title='hlava, páteř a krk'>
          {examGroups.neuro.map(item => (
            <Menu.Item key={item.value}>{item.Selector}</Menu.Item>
          ))}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='klouby'>
          {examGroups.MSK.map(item => (
            <Menu.Item key={item.value}>{item.Selector}</Menu.Item>
          ))}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='břicho a pánev'>
          {examGroups.abdominopelvic.map(item => (
            <Menu.Item key={item.value}>{item.Selector}</Menu.Item>
          ))}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='staging'>
          {examGroups.staging.map(item => (
            <Menu.Item key={item.value}>{item.Selector}</Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    );

    return (
      <Form key={this.state.key}>
        <Row style={{ backgroundColor: colorMRI }} align='middle'>
          <Col flex='310px'>
            <Dropdown placement='bottomLeft' trigger={['click']} overlay={menu}>
              <Button
                size='large'
                type='primary'
                style={{ background: colorMRI, borderColor: colorMRI }}
                icon={<PlusOutlined />}
              >
                {this.state.panes.length === 0
                  ? 'nové vyšetření...'
                  : 'přidat vyšetření...'}
              </Button>
            </Dropdown>
          </Col>
          <Col flex='210px' style={{ paddingRight: '5px' }}>
            {this.state.panes.length > 0 ? (
              <DropDown
                //value={this.state.report.contrast}
                defaultValue={this.state.report.contrast}
                name='contrast'
                segment='report'
                options={aContrastMR}
                onChange={this.onChange}
              />
            ) : null}
          </Col>
          <Col flex='auto' style={{ paddingRight: '5px' }}>
            {this.state.panes.length > 0 && this.state.report.contrast > 0 ? (
              <DropDown
                defaultValue={this.state.report.contrastAmount}
                name='contrastAmount'
                segment='report'
                options={aContrastMRamount}
                onChange={this.onChange}
              />
            ) : null}
          </Col>
          <Col flex='536px' style={{ padding: '2px 10px 5px 0px' }}>
            {this.state.panes.length > 0 && (
              <TextArea
                size='small'
                name='indication'
                segment='report'
                defaultValue={this.state.report.indication}
                placeholder='indikace...'
                autoSize={{ minRows: 1, maxRows: 1 }}
                onBlur={event =>
                  this.onChange({
                    name: 'indication',
                    segment: 'report',
                    value: removeAllNewLines(event.target.value),
                  })
                }
              />
            )}
          </Col>
          <Col>
            {this.state.panes.length > 0 ? (
              <Button
                type='primary'
                size='small'
                danger
                style={{ width: 65, marginRight: 12 }}
                onClick={this.resetExam}
              >
                reset
              </Button>
            ) : null}
          </Col>
        </Row>

        {panes.length > 0 ? (
          <Row>
            <Col>
              <Tabs
                type='editable-card'
                hideAdd={true}
                onChange={this.onChangeTabs}
                activeKey={activeKey}
                onEdit={this.onEdit}
              >
                {panes.map((pane, index) => (
                  <TabPane
                    tab={
                      <>
                        {pane.TabName}
                        {pane.protocols.length > 1 ? (
                          <Select
                            size='small'
                            style={{ width: '175px' }}
                            defaultValue={0}
                            onChange={event =>
                              this.updateProtocol({
                                pane: index,
                                protocol: event || 0,
                              })
                            }
                          >
                            {pane.protocols.map((item, i) => {
                              return (
                                <Option key={i} value={item.value}>
                                  {item.tab}
                                </Option>
                              );
                            })}
                          </Select>
                        ) : null}
                      </>
                    }
                    key={pane.key}
                  >
                    {this.renderComponent(
                      pane.TagName,
                      this.getReport,
                      pane.value,
                      index
                    )}
                  </TabPane>
                ))}
              </Tabs>
              <Report_new data={this.state} />
            </Col>
          </Row>
        ) : null}
      </Form>
    );
  }
}

export default ExamHolder_MR;
