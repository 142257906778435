// Custom scripts
import {
  aFazekas,
  aGliosisInfratentorial,
  aGCA,
  aMTA,
  aKoedam,
  aFocalAtrophy,
  aPituitary,
  aMastoid,
  aSeptumNasi,
  aConchaBullosa,
  aPerivascSpaces,
  aSeptumPellucidum,
  aCalvarium,
  aLymbic,
  aIntraaxial,
  aArteriesVariety,
  aDemyelinSupra,
  aDemyelinSupraFilled,
  aDemyelinInfra,
  aDemyelinInfraFilled,
  aDemyelinIntramed,
  aDemyelinIntramedFilled,
  aLatVentAsymetry,
  aSinusFR,
  aSinusFL,
  aSinusMR,
  aSinusML,
  aSinusER,
  aSinusEL,
  aSinusSR,
  aSinusSL,
} from './MR_brain_arrays';

// General scripts
import { indent, br } from './../scripts/GeneralArrays';
import { formatArrayToText } from './../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Custom
  const { custom } = props;
  let customLesion = custom.filter(item => item.patType === '0');
  let customAtrophy = custom.filter(item => item.patType === '1');
  let customOther = custom.filter(item => item.patType === '2');
  let customVariety = custom.filter(item => item.patType === '3');

  // Zadní jáma
  const { posteriorFossaDescription, posteriorFossaConclusion } = props.general;
  // Glióza, ložiska
  const { Fazekas, gliosisInfratentorial } = props.general;
  // Demyelinizace
  const {
    demyelinSupraDescription,
    demyelinSupraConclusion,
    demyelinSupraButton,
    demyelinInfraDescription,
    demyelinInfraConclusion,
    demyelinInfraButton,
    demyelinIntramedDescription,
    demyelinIntramedConclusion,
    demyelinIntramedButton,
  } = props.general;
  // Atrofie
  const { GCA, MTAright, MTAleft, Koedam, focalAtrophy } = props.general;
  // Ostatní
  const {
    pituitary,
    pituitaryDescription,
    pituitaryConclusion,
    RathkeCystSize,
    pinealCyst,
    pinealCystSize,
    cavernoma,
    DVA,
    xanthogranulomas,
    mastoidRight,
    mastoidLeft,
    lensRight,
    lensLeft,
    sinusFR,
    sinusFL,
    sinusMR,
    sinusML,
    sinusER,
    sinusEL,
    sinusSR,
    sinusSL,
    perivascSpaces,
    septumPellucidum,
    calvarium,
    ependymitisGranularis,
    lymbic,
    megacisterna,
    falxCalc,
    septumNasi,
    concha,
    latVentAsymetry,
    arterialMRA,
    venousMRA,
    posterioFossa,
  } = props.general;

  // Normální nález
  let normalFindings = '';
  if (
    Fazekas +
      gliosisInfratentorial +
      GCA +
      MTAright +
      MTAleft +
      Koedam +
      focalAtrophy +
      pituitary +
      cavernoma ===
    0
  ) {
    normalFindings = 'Přiměřený nález na MR mozku. ';
  }

  // Vlastní záznam
  let customLesionDescription = customLesion
    .map(item => item.description)
    .join('');
  let customLesionConclusion = customLesion
    .map(item => item.conclusion)
    .join('');

  // Zadní jáma
  let posteriorFossaDesc = '';
  let posteriorFossaConc = '';
  if (protocol === 1 || posterioFossa) {
    posteriorFossaDesc =
      posteriorFossaDescription === ''
        ? 'Bez strukturálních či signálových změn oblasti mostomozečkových koutů, vnitřních zvukovodů a nebo vnitřního ucha bilat. '
        : posteriorFossaDescription +
          'Jinak přiměřený nález v zadní jámě a skalních kostech. ';
    posteriorFossaConc =
      posteriorFossaConclusion === ''
        ? 'Bez průkazu patologie v zadní jámě, vnitřním zvukovodu či vnitřním uchu bilat. '
        : posteriorFossaConclusion;
  }

  // RS
  let gliosisDescription = '';
  let gliosisConclusion = '';

  let demyelinisationDescription = '';
  let demyelinisationConclusion = '';

  if (protocol === 5) {
    demyelinisationDescription +=
      demyelinSupraDescription +
      (demyelinSupraDescription != ''
        ? aDemyelinSupraFilled[demyelinSupraButton].description
        : aDemyelinSupra[demyelinSupraButton].description) +
      demyelinInfraDescription +
      (demyelinInfraDescription != ''
        ? aDemyelinInfraFilled[demyelinInfraButton].description
        : aDemyelinInfra[demyelinInfraButton].description) +
      demyelinIntramedDescription +
      (demyelinIntramedDescription != ''
        ? aDemyelinIntramedFilled[demyelinIntramedButton].description
        : aDemyelinIntramed[demyelinIntramedButton].description);

    demyelinisationConclusion +=
      demyelinSupraConclusion +
      demyelinInfraConclusion +
      demyelinIntramedConclusion +
      (demyelinSupraConclusion === '' &&
      demyelinInfraConclusion === '' &&
      demyelinIntramedConclusion === ''
        ? 'Oproti '
        : 'Jinak oproti ') +
      (demyelinSupraButton + demyelinInfraButton + demyelinIntramedButton === 0
        ? 'minule bez přesvědčivé progrese velikosti či počtu supratentoriálních, infratentoriálních a intramedulárních ložisek obrazu demyelinizace. '
        : demyelinSupraButton === 0 &&
          demyelinInfraButton === 0 &&
          demyelinIntramedButton > 0
        ? 'minule bez progrese velikosti či počtu supratentoriálních a infratentoriálních ložisek obrazu demyelinizace. Bez přesvědčivých intramedulárních ložisek. '
        : demyelinSupraButton === 0 &&
          demyelinInfraButton > 0 &&
          demyelinIntramedButton > 0
        ? 'minule bez progrese velikosti či počtu supratentoriálních ložisek obrazu demyelinizace. Bez přesvědčivých infratentoriálních a intramedulárních ložisek. '
        : demyelinSupraButton === 0 &&
          demyelinInfraButton > 0 &&
          demyelinIntramedButton === 0
        ? 'minule bez progrese velikosti či počtu supratentoriálních a intramedulárních ložisek obrazu demyelinizace. Bez přesvědčivých infratentoriálních ložisek. '
        : null);
  } else {
    gliosisDescription +=
      pituitaryDescription +
      aFazekas[Fazekas].description +
      aGliosisInfratentorial[gliosisInfratentorial].description +
      (Fazekas + gliosisInfratentorial + customLesion.length === 0 &&
      posteriorFossaDescription === ''
        ? 'Bez strukturálních či signálových změn mozku supratentoriálně a nebo infratentoriálně. '
        : 'Jinak bez strukturálních či signálových změn mozku supratentoriálně a nebo infratentoriálně. ') +
      (protocol === 3 && contrast > 0
        ? 'Nativně bez ložiskových změn hypofýzy, bez invaze okolních struktur. Postkontrastně na dynamických sekvencích bez přesvědčivých ložisek opožděného sycení v parenchymu hypofýzy. Infundibulum hypofýzy není rozšířeno, probíhá ve střední čáře. '
        : '') +
      (protocol === 4 && contrast > 0
        ? 'Postkontrastně bez přesvědčivého sycení stěny mozkových tepen. '
        : '') +
      (protocol === 8
        ? 'V hodnotitelném rozsahu pravidelná šíře šedé hmoty, bez setření rozhraní šedé a bílé hmoty. V periventrikulární bílé hmotě bez přesvědčivých okrsků signálu šedé hmoty. Hipokampy normálního vzhledu. '
        : '') +
      (pituitaryDescription === ''
        ? aPituitary[pituitary].description +
          (pituitary === 3 ? RathkeCystSize + 'mm. ' : '') +
          (pinealCyst
            ? 'Ovoidní ložisko v oblasti epifýzy' +
              (pinealCystSize !== ''
                ? ' velikosti ' + pinealCystSize + 'mm'
                : '') +
              ', na T2 vysoké SI, na FLAIR mírně vyšší SI. '
            : '')
        : '');
  }

  gliosisConclusion +=
    pituitaryConclusion +
    aFazekas[Fazekas].conclusion +
    aGliosisInfratentorial[gliosisInfratentorial].conclusion;

  // Atrofie

  let atrophyDescription = '';
  let atrophyConclusion = '';

  atrophyDescription =
    GCA +
      Koedam +
      focalAtrophy +
      MTAleft +
      MTAright +
      latVentAsymetry +
      customAtrophy.length ===
    0
      ? 'Věku přiměřená šíře subarachnoidálních prostor a komorového systému. ' +
        (customLesion.length === 0 ? 'Bez deviace středových struktur. ' : '')
      : customAtrophy.map(item => item.description).join('') +
        aGCA[GCA].description +
        aKoedam[Koedam].description +
        aFocalAtrophy[focalAtrophy].description +
        (MTAleft === MTAright
          ? 'Bilat. ' + aMTA[MTAright].description
          : 'Vpravo ' +
            aMTA[MTAright].description +
            'Vlevo ' +
            aMTA[MTAleft].description) +
        aLatVentAsymetry[latVentAsymetry].description +
        'Jinak věku přiměřená šíře subarachnoidálních prostor a komorového systému. ' +
        (customLesion.length === 0 ? 'Bez deviace středových struktur' : '');

  atrophyConclusion +=
    customAtrophy.map(item => item.conclusion).join('') +
    aGCA[GCA].conclusion +
    aKoedam[Koedam].conclusion +
    aFocalAtrophy[focalAtrophy].conclusion +
    (MTAright === 0 && MTAleft === 0
      ? ''
      : MTAright === MTAleft
      ? 'Mediální temporální atrofie (MTA bilat. ' +
        aMTA[MTAright].conclusion +
        '). '
      : MTAright > 0 && MTAleft > 0
      ? 'Mediální temporální atrofie (MTA vpravo ' +
        aMTA[MTAright].conclusion +
        ', vlevo ' +
        aMTA[MTAleft].conclusion +
        '). '
      : MTAright === 0
      ? 'Mediální temporální atrofie vlevo (MTA ' +
        aMTA[MTAleft].conclusion +
        '). '
      : 'Mediální temporální atrofie vpravo (MTA ' +
        aMTA[MTAright].conclusion +
        '). ');

  // Ostatní

  let otherDescription = '';
  let otherConclusion = '';

  // Vedlejší dutiny nosní ()

  let aMildSinuses = [];
  let aModerateSinuses = [];
  let aSevereSinuses = [];
  let aAplasiaSinuses = [];
  let aAtelectasiaSinuses = [];
  let aRetCystSinuses = [];
  let aRetCystsSinuses = [];

  let sinusDescription = '';
  let sinusConclusion = '';

  let analyzeSinuses = (sinusR, sinusL) => {
    var text = '';
    let fillSinuses = (sinus, text) => {
      var text = text || sinus.description + ' sinu';
      switch (sinus.value) {
        case 1:
          aMildSinuses.push(text);
          break;
        case 2:
          aModerateSinuses.push(text);
          break;
        case 3:
          aSevereSinuses.push(text);
          break;
        case 4:
          aAplasiaSinuses.push(text);
          break;
        case 5:
          aAtelectasiaSinuses.push(text);
          break;
        case 6:
          aRetCystSinuses.push(text);
          break;
        case 7:
          aRetCystsSinuses.push(text);
          break;
        default:
          break;
      }
    };
    if (sinusR.value > 0 && sinusL.value > 0) {
      if (sinusR.value === sinusL.value) {
        if (sinusR.label === 'FR') {
          text = 'obou frontálních sinů';
        } else if (sinusR.label === 'MR') {
          text = 'obou maxilárních sinů';
        } else if (sinusR.label === 'ER') {
          text = 'obou etmoidálních sinů';
        } else if (sinusR.label === 'SR') {
          text = 'obou sfenoidálních sinů';
        }
        fillSinuses(sinusR, text);
      } else {
        fillSinuses(sinusR);
        fillSinuses(sinusL);
      }
    } else if (sinusR.value > 0) {
      fillSinuses(sinusR);
    } else if (sinusL.value > 0) {
      fillSinuses(sinusL);
    }
  };

  analyzeSinuses(aSinusFR[sinusFR], aSinusFL[sinusFL]);
  analyzeSinuses(aSinusMR[sinusMR], aSinusML[sinusML]);
  analyzeSinuses(aSinusER[sinusER], aSinusEL[sinusEL]);
  analyzeSinuses(aSinusSR[sinusSR], aSinusSL[sinusSL]);

  if (aSevereSinuses.length > 0) {
    let sinuses = formatArrayToText(aSevereSinuses) + '. ';
    sinusDescription +=
      'Na T2 a FLAIR hypersignální výrazné zesílení sliznice ' + sinuses;
    sinusConclusion += 'Pokročilé hyperplastické slizniční změny ' + sinuses;
  }

  if (aModerateSinuses.length > 0) {
    let sinuses = formatArrayToText(aModerateSinuses) + '. ';
    sinusDescription +=
      'Na T2 a FLAIR hypersignální zesílení sliznice ' + sinuses;
    sinusConclusion +=
      'Středně pokročilé hyperplastické slizniční změny ' + sinuses;
  }

  if (aMildSinuses.length > 0) {
    let sinuses = formatArrayToText(aMildSinuses) + '. ';
    sinusDescription +=
      'Na T2 a FLAIR hypersignální mírné zesílení sliznice ' + sinuses;
    sinusConclusion += 'Mírné hyperplastické slizniční změny ' + sinuses;
  }

  if (aAplasiaSinuses.length > 0) {
    let sinuses = formatArrayToText(aAplasiaSinuses) + '. ';
    sinusDescription += 'Aplázie ' + sinuses;
  }

  if (aAtelectasiaSinuses.length > 0) {
    let sinuses = formatArrayToText(aAtelectasiaSinuses) + '. ';
    sinusDescription += 'Zmenšení objemu a zesílení sliznice ' + sinuses;
    sinusConclusion += 'Pozánětlivá atelektáza ' + sinuses;
  }

  if (aAtelectasiaSinuses.length > 0) {
    let sinuses = formatArrayToText(aAtelectasiaSinuses) + '. ';
    sinusDescription += 'Zmenšení objemu a zesílení sliznice ' + sinuses;
    sinusConclusion += 'Pozánětlivá atelektáza ' + sinuses;
  }

  if (aRetCystSinuses.length > 0) {
    let sinuses = formatArrayToText(aRetCystSinuses) + '. ';
    sinusDescription +=
      'Navalitá na T2 a FLAIR hypersignální struktura při stěně ' + sinuses;
    sinusConclusion += 'Retenční cysta ' + sinuses;
  }

  if (aRetCystsSinuses.length > 0) {
    let sinuses = formatArrayToText(aRetCystsSinuses) + '. ';
    sinusDescription +=
      'Navalité na T2 a FLAIR hypersignální struktury při stěně ' + sinuses;
    sinusConclusion += 'Retenční cysty ' + sinuses;
  }

  if (
    aMildSinuses.length +
      aModerateSinuses.length +
      aSevereSinuses.length +
      aAplasiaSinuses.length +
      aAtelectasiaSinuses.length >
    0
  ) {
    sinusDescription += 'Jinak přiměřený nález na paranazálních sinech. ';
  } else {
    sinusDescription +=
      'Paranazální siny jsou vzdušné, bez patologického obsahu. ';
  }

  otherDescription +=
    (customOther.length > 0
      ? customOther.map(item => item.description).join('')
      : '') +
    (cavernoma > 0
      ? 'Na SWI kulovitý okrsek artefaktů z hemosiderinu v bílé hmotě ' +
        aIntraaxial[cavernoma].description
      : '') +
    (DVA > 0
      ? 'Na SWI do periferie se větvící tenká hyposignální struktura v bílé hmotě ' +
        aIntraaxial[DVA].description
      : '') +
    (xanthogranulomas
      ? 'V choroidálním plexu trigona pravé i levé postranní komory na T2 a DWI kulovité ložisko vysoké SI (xanthogranulomy choroidálních plexů). '
      : '') +
    sinusDescription +
    aSeptumNasi[septumNasi].description +
    aConchaBullosa[concha].description +
    (mastoidRight === 0 && mastoidLeft === 0
      ? 'Mastoidální sklípky ' + aMastoid[mastoidRight].description
      : mastoidRight > 0 && mastoidRight === mastoidLeft
      ? 'Mastoidální sklípky bilat. ' + aMastoid[mastoidRight].description
      : 'Pravostranné mastoidální sklípky ' +
        aMastoid[mastoidRight].description +
        'Levostranné mastoidální sklípky ' +
        aMastoid[mastoidLeft].description) +
    (!lensRight && !lensLeft
      ? ''
      : lensRight && lensLeft
      ? 'Náhrada obou očních čoček. '
      : lensRight
      ? 'Náhrada pravé oční čočky. '
      : lensLeft
      ? 'Náhrada levé oční čočky. '
      : '') +
    'Normální obsah orbit. ';

  otherConclusion +=
    (pinealCyst
      ? 'Pineální cysta' +
        (pinealCystSize !== '' ? ' (' + pinealCystSize + 'mm). ' : '. ')
      : '') +
    (cavernoma > 0 ? 'Kavernom ' + aIntraaxial[cavernoma].conclusion : '') +
    (DVA > 0
      ? 'Vývojová žilní anomálie (DVA) ' + aIntraaxial[DVA].conclusion
      : '') +
    aPituitary[pituitary].conclusion +
    (pituitary === 3 && RathkeCystSize > 0
      ? ' (' + RathkeCystSize + 'mm). '
      : pituitary === 3
      ? '. '
      : '') +
    (protocol !== 5 ? normalFindings : '') +
    sinusConclusion +
    (mastoidRight === 0 && mastoidLeft === 0
      ? ''
      : mastoidRight > 0 && mastoidRight === mastoidLeft
      ? 'Mastoidální sklípky bilat. ' + aMastoid[mastoidRight].conclusion
      : mastoidRight > 0 && mastoidLeft > 0
      ? 'Pravostranné mastoidální sklípky ' +
        aMastoid[mastoidRight].conclusion +
        'Levostranné mastoidální sklípky ' +
        aMastoid[mastoidLeft].conclusion
      : mastoidRight > 0
      ? 'Pravostranné mastoidální sklípky ' + aMastoid[mastoidRight].conclusion
      : 'Levostranné mastoidální sklípky ' + aMastoid[mastoidLeft].conclusion) +
    (customOther.length > 0
      ? customOther.map(item => item.conclusion).join('')
      : '');

  // Mozkové tepny
  let { brainArtDescription, brainArtConclusion, A1R, A1L, P1R, P1L, VR, VL } =
    props.general;

  let arteriesDescription = '';
  let arteriesConclusion = '';
  if (brainArtDescription !== '') {
    arteriesDescription +=
      brainArtDescription +
      'Jinak přiměřený nález na mozkových tepnách, bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  } else {
    arteriesDescription +=
      'Přiměřený nález na mozkových tepnách, bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  }

  if (brainArtConclusion !== '') {
    arteriesConclusion +=
      brainArtConclusion +
      'Jinak mozkové tepny bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  } else {
    arteriesConclusion +=
      'Mozkové tepny bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  }

  if (A1R + A1L + P1R + P1L + VR + VL > 0) {
    if (A1R > 0)
      arteriesDescription +=
        aArteriesVariety[A1R].description +
        'pravého A1 segmentu, a. cerebri ant. se tak plní cestou přední komunikanty. ';
    if (A1L > 0)
      arteriesDescription +=
        aArteriesVariety[A1L].description +
        'levého A1 segmentu, a. cerebri ant. se tak plní cestou přední komunikanty. ';
    if (P1R > 0)
      arteriesDescription +=
        aArteriesVariety[P1R].description +
        'pravého P1 segmentu, a. cerebri post. se tak plní cestou zadní komunikanty. ';
    if (P1L > 0)
      arteriesDescription +=
        aArteriesVariety[P1L].description +
        'levého P1 segmentu, a. cerebri post. se tak plní cestou zadní komunikanty. ';
    if (VR > 0)
      arteriesDescription +=
        aArteriesVariety[VR].description +
        'koncového úseku pravé vertebrální tepny. ';
    if (VL > 0)
      arteriesDescription +=
        aArteriesVariety[VL].description +
        'koncového úseku levé vertebrální tepny. ';
  }

  // Žilní splavy
  let {
    brainVeinsDescription,
    brainVeinsConclusion,
    sinusTransR,
    sinusTransL,
    sinusSigmoidR,
    sinusSigmoidL,
  } = props.general;

  let veinsDescription = '';
  let veinsConclusion = '';

  if (brainVeinsDescription !== '') {
    veinsDescription +=
      brainVeinsDescription + 'Jinak přiměřený nález na žilních splavech. ';
  } else {
    veinsDescription += 'Bez defektů v normální náplni žilních splavů. ';
  }

  if (brainVeinsConclusion !== '') {
    veinsConclusion +=
      brainVeinsConclusion + 'Jinak normální nález na žilních splavech. ';
  } else {
    veinsConclusion += 'Bez známek trombózy žilních splavů. ';
  }

  if (sinusTransR + sinusTransL + sinusSigmoidR + sinusSigmoidL > 0) {
    if (sinusTransR > 0 || sinusSigmoidR > 0) {
      if (sinusTransR === sinusSigmoidR) {
        veinsDescription +=
          aArteriesVariety[sinusTransR].description +
          'pravého sinus transversus a sigmoideus. ';
      } else {
        if (sinusTransR > 0) {
          veinsDescription +=
            aArteriesVariety[sinusTransR].description +
            'pravého sinus transversus. ';
        }
        if (sinusSigmoidR > 0) {
          veinsDescription +=
            aArteriesVariety[sinusSigmoidR].description +
            'pravého sinus sigmoideus. ';
        }
      }
    }
    if (sinusTransL > 0 || sinusSigmoidL > 0) {
      if (sinusTransL === sinusSigmoidL) {
        veinsDescription +=
          aArteriesVariety[sinusTransL].description +
          'levého sinus transversus a sigmoideus. ';
      } else {
        if (sinusTransL > 0) {
          veinsDescription +=
            aArteriesVariety[sinusTransL].description +
            'levého sinus transversus. ';
        }
        if (sinusSigmoidL > 0) {
          veinsDescription +=
            aArteriesVariety[sinusSigmoidL].description +
            'levého sinus sigmoideus. ';
        }
      }
    }
  }

  // Variety

  let varietiesDescription =
    (customVariety.length > 0
      ? customVariety.map(item => item.description).join('')
      : '') +
    aPerivascSpaces[perivascSpaces].description +
    (ependymitisGranularis
      ? 'Poměrně symetrické lemy vyšší SI na T2 a FLAIR podél frontálních rohů postranních komor (varietní ependymitis granularis). '
      : '') +
    aSeptumPellucidum[septumPellucidum].description +
    aCalvarium[calvarium].description +
    aLymbic[lymbic].description +
    (megacisterna ? 'Megacisterna magna (varieta). ' : '') +
    (falxCalc
      ? 'Na SWI hrudkovité okrsky nízké SI v průběhu falx cerebri (degenerativní kalcifikace falx cerebri). '
      : '');

  importDescription1(
    posteriorFossaDesc +
      customLesionDescription +
      demyelinisationDescription +
      gliosisDescription +
      atrophyDescription
  );
  importDescription1(otherDescription + varietiesDescription);
  importDescription1(protocol === 6 || arterialMRA ? arteriesDescription : '');
  importDescription1(protocol === 7 || venousMRA ? veinsDescription : '');

  importConclusion1(
    demyelinisationConclusion +
      posteriorFossaConc +
      customLesionConclusion +
      gliosisConclusion +
      atrophyConclusion +
      otherConclusion +
      (protocol === 6 || arterialMRA ? arteriesConclusion : '') +
      (protocol === 7 || venousMRA ? veinsConclusion : '')
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
