import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aExams,
  aSynovialFluid,
  aArthrosis,
  aLCA,
  aLCP,
  aLCM,
  aLCL,
  aLCABoneContusion,
  aMenisci,
  aMeniscusPart,
  aMeniscusGrade,
  aMeniscusSurface,
  aMeniscusTearType,
  aChondropathyCompartment,
  aChondropathyArea,
  aChondropathyAreaPatella,
  aChondropathyGrade,
  aBursitisSuprapatellar,
  aBursitisPrepatellar,
  aBursitisInfrapatellarSuperf,
  aBursitisInfrapatellarDeep,
  aArthrosisFP,
  aWiberg,
} from "./MR_knee_arrays";
import { generateReport } from "./MR_knee_functions";

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import DropDown from "../components/DropDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import ButtonUpDown from "../components/ButtonUpDown";
import CustomCards from "../components/CustomCards";

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
  Tooltip,
  Select,
} from "antd";
import { PlusSquareTwoTone, DeleteOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;

class MR_knee extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    FT: {
      synovialFluid: 0,
      BakerCyst: false,
      BakerCystSize: "",
      LCACyst: false,
      LCACystSize: "",
      LCPCyst: false,
      LCPCystSize: "",
      GCNMCyst: false,
      GCNMCystSize: "",
      GCNLCyst: false,
      GCNLCystSize: "",
      varices: false,
      infrapatplica: false,
      arthrosis: 0,
      LCA: 0,
      LCADescription: "",
      LCAConclusion: "",
      LCP: 0,
      LCM: 0,
      LCL: 0,
      LCABoneContusion: 0,
    },
    FP: {
      arthrosisFP: 0,
      Wiberg: 1,
      dislocationFP: 0,
    },
    bursitis: {
      suprapatellar: 0,
      prepatellar: 0,
      infrapatellarSuperf: 0,
      infrapatellarDeep: 0,
    },
    general: {
      customAddFindsDescription: "",
      customAddFindsConclusion: "",
    },
    lesions: [],
    menisci: [],
    chondropathy: [],
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const segment = event.segment;
    const name = event.name;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRowMenisci = (event) => {
    let side = event.item.props.value;
    let part = 4; // zadní roh
    let grade = 2; // grade 3
    let surface = 1; // dolní
    let tearType = 0;
    let flap = 0;

    let newMeniscusPat = {
      side: side,
      part: part,
      grade: grade,
      surface: surface,
      tearType: tearType,
      flap: flap,
    };

    this.setState((prevState) => ({
      menisci: [...prevState.menisci, newMeniscusPat],
    }));
  };

  newRowChondropathy = (event) => {
    let compartment = event.item.props.value;
    let area = 0; // centrální část zátěžové zóny
    let grade = 0; // grade 1
    let size = "";

    let newChondropathy = {
      compartment: compartment,
      area: area,
      grade: grade,
      size: size,
    };

    this.setState((prevState) => ({
      chondropathy: [...prevState.chondropathy, newChondropathy],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;

    this.setState({ [segment]: array });
  };

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];

    array.splice(index, 1);

    this.setState({ [segment]: array });
  };

  onSwitch = (event) => {
    let segment = event.segment;
    let name = event.name;
    let value = name === "BakerCyst" ? event.value : event.value.target.value;
    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  render() {
    const { protocol } = this.props;
    const myContent1 = [
      {
        title: "femorotibiální kloub",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "degenerace",
              input: (
                <ButtonUpDown
                  name="arthrosis"
                  segment="FT"
                  options={aArthrosis}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "náplň kloubu",
              input: (
                <ButtonUpDown
                  name="synovialFluid"
                  segment="FT"
                  options={aSynovialFluid}
                  onChange={this.onChange}
                />
              ),
            },
          ],

          [
            {
              label: "Baker. cysta",
              input: (
                <Row>
                  {this.state.FT.BakerCyst ? (
                    <>
                      <Tooltip
                        placement="left"
                        title="Bakerova cysta"
                        mouseEnterDelay="1"
                      >
                        <Col span={12}>
                          <ButtonTrueFalse
                            name="BakerCyst"
                            segment="FT"
                            value={this.state.FT.BakerCyst}
                            options={aTrueFalse}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Tooltip>
                      <Col>
                        <Input
                          name="BakerCystSize"
                          value={this.state.FT.BakerCystSize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "FT",
                              name: "BakerCystSize",
                              value: event.target.value,
                            })
                          }
                          style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <Tooltip
                      placement="left"
                      title="Bakerova cysta"
                      mouseEnterDelay="1"
                    >
                      <Col span={24}>
                        <ButtonTrueFalse
                          name="BakerCyst"
                          segment="FT"
                          value={this.state.FT.BakerCyst}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Tooltip>
                  )}
                </Row>
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: "cysta LCA",
              input: (
                <Row>
                  {this.state.FT.LCACyst ? (
                    <>
                      <Tooltip
                        placement="left"
                        title="ganglionová cysta LCA"
                        mouseEnterDelay="1"
                      >
                        <Col span={12}>
                          <ButtonTrueFalse
                            name="LCACyst"
                            segment="FT"
                            value={this.state.FT.LCACyst}
                            options={aTrueFalse}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Tooltip>
                      <Col>
                        <Input
                          name="LCPCystSize"
                          value={this.state.FT.LCACystSize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "FT",
                              name: "LCACystSize",
                              value: event.target.value,
                            })
                          }
                          style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <Tooltip
                      placement="left"
                      title="ganglionová cysta LCA"
                      mouseEnterDelay="1"
                    >
                      <Col span={24}>
                        <ButtonTrueFalse
                          name="LCACyst"
                          segment="FT"
                          value={this.state.FT.LCACyst}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Tooltip>
                  )}
                </Row>
              ),
            },
            {
              label: "cysta LCP",
              input: (
                <Row>
                  {this.state.FT.LCPCyst ? (
                    <>
                      <Tooltip
                        placement="left"
                        title="ganglionová cysta LCP"
                        mouseEnterDelay="1"
                      >
                        <Col span={12}>
                          <ButtonTrueFalse
                            name="LCPCyst"
                            segment="FT"
                            value={this.state.FT.LCPCyst}
                            options={aTrueFalse}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Tooltip>
                      <Col>
                        <Input
                          name="LCPCystSize"
                          value={this.state.FT.LCPCystSize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "FT",
                              name: "LCPCystSize",
                              value: event.target.value,
                            })
                          }
                          style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <Tooltip
                      placement="left"
                      title="ganglionová cysta LCP"
                      mouseEnterDelay="1"
                    >
                      <Col span={24}>
                        <ButtonTrueFalse
                          name="LCPCyst"
                          segment="FT"
                          value={this.state.FT.LCPCyst}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Tooltip>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: "cysta GCN m.",
              input: (
                <Row>
                  {this.state.FT.GCNMCyst ? (
                    <>
                      <Tooltip
                        placement="left"
                        title="ganglionová cysta origa med. hlavy m. gastrocnemius"
                        mouseEnterDelay="1"
                      >
                        <Col span={12}>
                          <ButtonTrueFalse
                            name="GCNMCyst"
                            segment="FT"
                            value={this.state.FT.GCNMCyst}
                            options={aTrueFalse}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Tooltip>
                      <Col>
                        <Input
                          name="GCNMCystSize"
                          value={this.state.FT.GCNMCystSize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "FT",
                              name: "GCNMCystSize",
                              value: event.target.value,
                            })
                          }
                          style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <Tooltip
                      placement="left"
                      title="ganglionová cysta origa med. hlavy m. gastrocnemius"
                      mouseEnterDelay="1"
                    >
                      <Col span={24}>
                        <ButtonTrueFalse
                          name="GCNMCyst"
                          segment="FT"
                          value={this.state.FT.GCNMCyst}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Tooltip>
                  )}
                </Row>
              ),
            },
            {
              label: "cysta GCN l.",
              input: (
                <Row>
                  {this.state.FT.GCNLCyst ? (
                    <>
                      <Tooltip
                        placement="left"
                        title="ganglionová cysta origa lat. hlavy m. gastrocnemius"
                        mouseEnterDelay="1"
                      >
                        <Col span={12}>
                          <ButtonTrueFalse
                            name="GCNLCyst"
                            segment="FT"
                            value={this.state.FT.GCNLCyst}
                            options={aTrueFalse}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Tooltip>
                      <Col>
                        <Input
                          name="GCNLCystSize"
                          value={this.state.FT.GCNLCystSize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "FT",
                              name: "GCNLCystSize",
                              value: event.target.value,
                            })
                          }
                          style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <Tooltip
                      placement="left"
                      title="ganglionová cysta origa lat. hlavy m. gastrocnemius"
                      mouseEnterDelay="1"
                    >
                      <Col span={24}>
                        <ButtonTrueFalse
                          name="GCNLCyst"
                          segment="FT"
                          value={this.state.FT.GCNLCyst}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Tooltip>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: "žilní varixy",
              input: (
                <Row>
                  <Col span={24}>
                    <ButtonTrueFalse
                      name="varices"
                      segment="FT"
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: "infrapat. pl. sy",
              input: (
                <Row>
                  <Col span={24}>
                    <ButtonTrueFalse
                      name="infrapatplica"
                      segment="FT"
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "femoropatelární kloub",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "degenerace",
              input: (
                <ButtonUpDown
                  name="arthrosisFP"
                  segment="FP"
                  value={this.state.FP.arthrosisFP}
                  options={aArthrosisFP}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "Wiberg",
              input: (
                <ButtonUpDown
                  name="Wiberg"
                  segment="FP"
                  value={this.state.FP.Wiberg}
                  options={aWiberg}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "po lat. luxaci",
              input: (
                <ButtonTrueFalse
                  name="dislocationFP"
                  segment="FP"
                  value={this.state.FP.dislocationFP}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
    ];
    const myContent2 = [
      {
        title: "tendinopatie",
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: "LCA",
              input: (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <ButtonUpDown
                          name="LCA"
                          segment="FT"
                          options={aLCA}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    {this.state.FT.LCA === 3 ? (
                      <Row>
                        <Col span={24}>
                          <ButtonUpDown
                            name="LCABoneContusion"
                            segment="FT"
                            options={aLCABoneContusion}
                            onChange={this.onChange}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    {this.state.FT.LCA === 4 ? (
                      <>
                        <Row>
                          <Col span={24}>
                            <TextArea
                              name="LCADescription"
                              defaultValue={this.state.FT.LCADescription}
                              size="small"
                              placeholder="popis..."
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.onChange({
                                  segment: "FT",
                                  name: "LCADescription",
                                  value: event.target.value,
                                })
                              }
                              style={{ width: "100%" }}
                              autoFocus
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <TextArea
                              name="LCAConclusion"
                              defaultValue={this.state.FT.LCAConclusion}
                              size="small"
                              placeholder="závěr..."
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={(event) =>
                                this.onChange({
                                  segment: "FT",
                                  name: "LCAConclusion",
                                  value: event.target.value,
                                })
                              }
                              style={{ width: "100%" }}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
              ),
            },
            {
              label: "LCP",
              input: (
                <ButtonUpDown
                  name="LCP"
                  segment="FT"
                  options={aLCP}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "LCM",
              input: (
                <ButtonUpDown
                  name="LCM"
                  segment="FT"
                  options={aLCM}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "LCL",
              input: (
                <ButtonUpDown
                  name="LCL"
                  segment="FT"
                  options={aLCL}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "bursitidy",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "suprapatel.",
              input: (
                <ButtonUpDown
                  name="suprapatellar"
                  segment="bursitis"
                  options={aBursitisSuprapatellar}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "prepatel.",
              input: (
                <ButtonUpDown
                  name="prepatellar"
                  segment="bursitis"
                  options={aBursitisPrepatellar}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "infrapat. povr.",
              input: (
                <ButtonUpDown
                  name="infrapatellarSuperf"
                  segment="bursitis"
                  options={aBursitisInfrapatellarSuperf}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "infrapat. hlub.",
              input: (
                <ButtonUpDown
                  name="infrapatellarDeep"
                  segment="bursitis"
                  options={aBursitisInfrapatellarDeep}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    const myContent3 = [
      {
        title: "meniskopatie",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {this.state.menisci.map((item, index) => {
                    return (
                      <Row key={index} align="middle">
                        <Col flex="24px">
                          <DeleteOutlined
                            key={"closeIcon" + index}
                            onClick={() => this.deleteRow(index, "menisci")}
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        </Col>
                        <Col span={3}>
                          <Select
                            key={index}
                            name="side"
                            value={item.side}
                            defaultValue={item.side}
                            size="small"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(index, "menisci", "side", value)
                            }
                          >
                            {aMenisci.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <Select
                            name="part"
                            value={item.part}
                            defaultValue={item.part}
                            size="small"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(index, "menisci", "part", value)
                            }
                          >
                            {aMeniscusPart.map((item) => {
                              return (
                                <Option value={item.value}>{item.label}</Option>
                              );
                            })}
                          </Select>
                        </Col>
                        <Col span={2}>
                          <Select
                            name="grade"
                            value={item.grade}
                            defaultValue={item.grade}
                            size="small"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(index, "menisci", "grade", value)
                            }
                          >
                            {aMeniscusGrade.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        {item.grade > 1 ? (
                          <Col span={4}>
                            <Select
                              value={item.tearType}
                              defaultValue={item.tearType}
                              size="small"
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "menisci",
                                  "tearType",
                                  value
                                )
                              }
                            >
                              {aMeniscusTearType.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        ) : null}
                        {item.grade === 2 &&
                        (item.tearType === 0 || item.tearType === 1) ? (
                          <Col span={2}>
                            <Select
                              name="surface"
                              value={item.surface}
                              defaultValue={item.surface}
                              size="small"
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "menisci",
                                  "surface",
                                  value
                                )
                              }
                            >
                              {aMeniscusSurface.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        ) : null}
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
          [
            {
              label: "",
              input: (
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <Menu onClick={this.newRowMenisci}>
                      {aMenisci.map((item) => (
                        <Menu.Item key={item.value} value={item.value}>
                          {item.label}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <PlusSquareTwoTone style={{ fontSize: "25px" }} />
                </Dropdown>
              ),
            },
          ],
        ],
      },
    ];

    const myContent4 = [
      {
        title: "chondropatie",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {this.state.chondropathy.map((item, index) => {
                    return (
                      <Row key={index} align="middle">
                        <Col flex="24px">
                          <DeleteOutlined
                            key={"closeIcon" + index}
                            onClick={() =>
                              this.deleteRow(index, "chondropathy")
                            }
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        </Col>
                        <Col span={4}>
                          <Select
                            key={index}
                            value={item.compartment}
                            defaultValue={item.compartment}
                            size="small"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "chondropathy",
                                "compartment",
                                value
                              )
                            }
                          >
                            {aChondropathyCompartment.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        <Col span={3}>
                          <Select
                            value={item.area}
                            defaultValue={item.area}
                            size="small"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "chondropathy",
                                "area",
                                value
                              )
                            }
                          >
                            {item.compartment < 4
                              ? aChondropathyArea.map((item) => {
                                  return (
                                    <Option value={item.value}>
                                      {item.label}
                                    </Option>
                                  );
                                })
                              : aChondropathyAreaPatella.map((item) => {
                                  return (
                                    <Option value={item.value}>
                                      {item.label}
                                    </Option>
                                  );
                                })}
                          </Select>
                        </Col>
                        <Col span={2}>
                          <Select
                            value={item.grade}
                            defaultValue={item.grade}
                            size="small"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "chondropathy",
                                "grade",
                                value
                              )
                            }
                          >
                            {aChondropathyGrade.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>

                        <Col span={2}>
                          <Input
                            value={item.size}
                            size="small"
                            placeholder="velikost"
                            suffix="mm"
                            onChange={(event) =>
                              this.updateRow(
                                index,
                                "chondropathy",
                                "size",
                                event.target.value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
          [
            {
              label: "",
              input: (
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <Menu onClick={this.newRowChondropathy}>
                      {aChondropathyCompartment.map((item) => (
                        <Menu.Item key={item.value} value={item.value}>
                          {item.label}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <PlusSquareTwoTone style={{ fontSize: "25px" }} />
                </Dropdown>
              ),
            },
          ],
        ],
      },
    ];

    const otherFindings = [
      {
        title: "ostatní nálezy",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "vlastní popis",
              input: (
                <TextArea
                  name="customAddFindsDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.customAddFindsDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "customAddFindsDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "vlastní závěr",
              input: (
                <TextArea
                  name="customAddFindsConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.customAddFindsConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "customAddFindsConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
        <CustomCards content={myContent3} />
        <CustomCards content={myContent4} />
        <CustomCards content={otherFindings} />
      </Form>
    );
  }
}

export default MR_knee;
