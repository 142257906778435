// Custom scripts
import {
  aGITSegments,
  aLength,
  aWallWidth,
  aEnhancementType,
  aEnhancementAmount,
  aWallEdema,
  aAscites,
  aProstateSize,
  aUterusGeneral,
  aUterusPosition,
  aOvarium,
  aLimitations,
  aNormalFindings,
  aLN,
  aLNcount,
} from "./MR_enterography_arrays";

// General scripts
import { indent, br } from "../scripts/GeneralArrays";
import { capitelizeFirstLetter } from "../scripts/GeneralFunctions";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    ascites,
    sigmoidDiverticles,
    sigmoidCustomDescription,
    sigmoidCustomConclusion,
    softTissuesCustomDescription,
    softTissuesCustomConclusion,
    bonesCustomDescription,
    bonesCustomConclusion,
    LNparaaortic,
    LNiliacComR,
    LNiliacComL,
    LNiliacExtR,
    LNiliacExtL,
    LNiliacIntR,
    LNiliacIntL,
    LNparaaorticCount,
    LNiliacComRCount,
    LNiliacComLCount,
    LNiliacExtRCount,
    LNiliacExtLCount,
    LNiliacIntRCount,
    LNiliacIntLCount,
    LNcustomDescription,
    LNcustomConclusion,
    limitations,
    normalFindings,
  } = props.general;
  const { GITsegments } = props;

  // Crohn disease activity score

  let GITsegmentsDescription = [];
  let GITsegmentsConclusion = [];
  GITsegments.map((segment, index) => {
    let CrohnScore = 0;
    let isComplication = false;
    // délka segmentu
    if (segment.length <= 5) CrohnScore += 1;
    if (segment.length > 5 && segment.length <= 15) CrohnScore += 2;
    if (segment.length > 15) CrohnScore += 3;
    // šíře stěny
    if (segment.wallWidth === 0) CrohnScore += 1;
    else if (segment.wallWidth === 1) CrohnScore += 2;
    else if (segment.wallWidth === 2) CrohnScore += 3;
    // T2FS signál
    if (segment.wallEdema === 0) CrohnScore += 1;
    else if (segment.wallEdema === 1) CrohnScore += 2;
    else if (segment.wallEdema === 2) CrohnScore += 3;
    // míra sycení
    if (segment.enhancementAmount === 0) CrohnScore += 1;
    else if (segment.enhancementAmount === 1) CrohnScore += 2;
    else if (segment.enhancementAmount === 2) CrohnScore += 3;
    // typ sycení
    if (segment.enhancementType === 0) CrohnScore += 1;
    else if (segment.enhancementType === 1) CrohnScore += 2;
    else if (segment.enhancementType === 2) CrohnScore += 3;
    // comb sign
    segment.combSign && (CrohnScore += 1);
    // komplikace
    segment.infiltrate && (isComplication = true);
    segment.fistula && (isComplication = true);
    segment.absces && (isComplication = true);
    segment.stenosis && (isComplication = true);
    GITsegmentsDescription.push(
      "Stěna " +
        aGITSegments[segment.GITsegment].description +
        " je v délce " +
        aLength[segment.length].description +
        " zesílena na " +
        aWallWidth[segment.wallWidth].description +
        ", na T2 s potlačením tuku je stěna " +
        aWallEdema[segment.wallEdema].description +
        ", postkontrastně s " +
        aEnhancementAmount[segment.enhancementAmount].description +
        " " +
        aEnhancementType[segment.enhancementType].description +
        " sycením stěny" +
        (segment.combSign
          ? ", v oblasti s rozšířenými cévami mezenteria (comb sign). "
          : ". ") +
        (segment.infiltrate
          ? "V oblasti " +
            aGITSegments[segment.GITsegment].description +
            " sytící se infiltrát postihující okolní tuk (zánětlivý pseudotumor). "
          : "") +
        (segment.fistula
          ? "Z oblasti " +
            aGITSegments[segment.GITsegment].description +
            " probíhá okolním tukem tubulární sytící se struktura (píštěl). "
          : "") +
        (segment.absces
          ? "Při oblasti " +
            aGITSegments[segment.GITsegment].description +
            " stěnou ohraničená kolekce tekutiny (absces). "
          : "") +
        (segment.stenosis
          ? "Zúžení lumen " +
            aGITSegments[segment.GITsegment].description +
            " s rozšířením orální části střevního lumen (stenóza s prestenotickou dilatací). "
          : "")
    );
    let aComplications = [];
    if (isComplication) {
      segment.infiltrate && aComplications.push("zánětlivý pseudotumor");
      segment.fistula && aComplications.push("píštěl");
      segment.absces && aComplications.push("absces");
      segment.stenosis &&
        aComplications.push("stenóza s prestenotickou dilatací");
    }
    if (isComplication) {
      GITsegmentsConclusion.push(
        "Výrazně pokročilá zánětlivá aktivita " +
          aGITSegments[segment.GITsegment].conclusion +
          " délky " +
          aLength[segment.length].description +
          " s komplikacemi (" +
          formatArrayToText(aComplications) +
          "). "
      );
    } else if (CrohnScore >= 14) {
      GITsegmentsConclusion.push(
        "Výrazně pokročilá zánětlivá aktivita " +
          aGITSegments[segment.GITsegment].conclusion +
          " délky " +
          aLength[segment.length].description +
          " bez komplikací. "
      );
    } else if (
      (CrohnScore >= 9 && CrohnScore <= 13) ||
      segment.wallWidth === 2 ||
      segment.wallEdema === 2 ||
      segment.enhancementAmount === 2 ||
      segment.enhancementType === 2 ||
      segment.length === 16
    ) {
      GITsegmentsConclusion.push(
        "Středně pokročilá zánětlivá aktivita " +
          aGITSegments[segment.GITsegment].conclusion +
          " délky " +
          aLength[segment.length].description +
          ". "
      );
    } else {
      GITsegmentsConclusion.push(
        "Mírná zánětlivá aktivita " +
          aGITSegments[segment.GITsegment].conclusion +
          " délky " +
          aLength[segment.length].description +
          ". "
      );
    }
  });

  // Střeva
  let otherGITDescription =
    sigmoidCustomDescription +
    (sigmoidDiverticles ? "Výchlipky stěny sigmatu. " : "") +
    (sigmoidDiverticles ||
    sigmoidCustomDescription !== "" ||
    GITsegments.length > 0
      ? "Jinak přiměřený nález na střevních kličkách. "
      : "Přiměřený nález na tenkých kličkách střevních, bez zesílení jejich stěny, bez nepřiměřeného postkontrastního sycení, bez stenózy či prestenotické dilatace. ");
  let otherGITConclusion =
    sigmoidCustomConclusion +
    (sigmoidDiverticles ? "Divertikly sigmatu. " : "");

  // Ascites
  let ascitesDesription = aAscites[ascites].description;
  let ascitesConclusion =
    protocol === 1 && ascites === 1
      ? "Malé množství volné tektuiny v Douglassově prostoru (premenopauzálně fyziologické). "
      : aAscites[ascites].conclusion;

  // Lymfatické uzliny
  let LNDescription = "";
  let LNConclusion = "";
  let LNisPat = false;

  // LN conclusion
  let aEnlargedLN = [];
  let aEnlargedLNcount = [];
  let aBorderlineLN = [];
  let aBorderlineLNcount = [];

  if (LNparaaortic > 1) {
    aEnlargedLNcount.push(LNparaaorticCount);
    aEnlargedLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  } else if (LNparaaortic === 1) {
    aBorderlineLNcount.push(LNparaaorticCount);
    aBorderlineLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  }
  if (LNiliacComR > 1) {
    aEnlargedLNcount.push(LNiliacComRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  } else if (LNiliacComR === 1) {
    aBorderlineLNcount.push(LNiliacComRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  }
  if (LNiliacComL > 1) {
    aEnlargedLNcount.push(LNiliacComLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  } else if (LNiliacComL === 1) {
    aBorderlineLNcount.push(LNiliacComLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  }
  if (LNiliacExtR > 1) {
    aEnlargedLNcount.push(LNiliacExtRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  } else if (LNiliacExtR === 1) {
    aBorderlineLNcount.push(LNiliacExtRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  }
  if (LNiliacExtL > 1) {
    aEnlargedLNcount.push(LNiliacExtLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  } else if (LNiliacExtL === 1) {
    aBorderlineLNcount.push(LNiliacExtLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  }
  if (LNiliacIntR > 1) {
    aEnlargedLNcount.push(LNiliacIntRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  } else if (LNiliacIntR === 1) {
    aBorderlineLNcount.push(LNiliacIntRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  }
  if (LNiliacIntL > 1) {
    aEnlargedLNcount.push(LNiliacIntLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  } else if (LNiliacIntL === 1) {
    aBorderlineLNcount.push(LNiliacIntLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  }

  if (aEnlargedLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aEnlargedLN) +
        (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose větší než 10mm. "
    );
    LNConclusion +=
      (aEnlargedLNcount[0] > 0 ? "Zvětšené " : "Zvětšená ") +
      formatArrayToText(aEnlargedLN) +
      (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (aBorderlineLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aBorderlineLN) +
        (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose přibližně 10mm. "
    );
    LNConclusion +=
      "Velikostí hraniční " +
      formatArrayToText(aBorderlineLN) +
      (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (aEnlargedLN.length > 0 || aBorderlineLN.length > 0) {
    LNDescription +=
      "Jinak v zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  } else {
    LNDescription +=
      "V zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  }

  // Měkké tkáně
  let softTissusDescription = softTissuesCustomDescription;
  let softTissuesConclusion = softTissuesCustomConclusion;

  // Skelet
  let bonesDescription =
    bonesCustomDescription === ""
      ? "Bez přesvědčivých ložiskových změn skeletu. "
      : bonesCustomDescription;
  let bonesConclusion = bonesCustomConclusion;

  // Normální nález
  let normalFindingsConclusion = "";
  if (normalFindings === 0) {
    if (
      GITsegments.length > 0 ||
      otherGITConclusion +
        LNConclusion +
        ascitesConclusion +
        softTissuesConclusion +
        bonesConclusion !==
        ""
    ) {
      normalFindingsConclusion = "Jinak přiměřený nález na MR enterografii. ";
    } else {
      normalFindingsConclusion = "Přiměřený nález na MR enterografii. ";
    }
  }

  // Limitace
  let limitationsDescription =
    limitations > 0 ? aLimitations[limitations].description : "";

  importDescription1(limitationsDescription);
  GITsegmentsDescription.map((segment) => importDescription1(segment));
  importDescription1(
    otherGITDescription +
      LNDescription +
      ascitesDesription +
      softTissusDescription +
      bonesDescription
  );

  GITsegmentsConclusion.map((segment) => importConclusion1(segment));
  importConclusion1(
    otherGITConclusion +
      LNConclusion +
      ascitesConclusion +
      softTissuesConclusion +
      bonesConclusion +
      normalFindingsConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
